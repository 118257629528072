// import './Header.css';
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import {Modal, Button, Form, Row, Col, Dropdown} from 'react-bootstrap'
import StepWizard from "react-step-wizard";

import CarrierRegistration from './CarrierRegistration';
import CarrierEnrollment from './CarrierEnrollment';
import DriverAdd from '../Driver/DriverAdd';

export default function CarrierWizard(props) {
  const [twoFeeTypes, setTwoFeeTypes] = useState(false);
  const addFormTwoHandler = () => setTwoFeeTypes(true);
  const removeFormTwoHandler = () => setTwoFeeTypes(false);
  const [show, setShow] = useState(props.pagePath == "registration" ? true:false);
  const handleClose = () => {setShow(false); props.setPagePath("login")};
  const handleShow = () => { setShow(true);  }
  
  let style = ""
  let onHide = handleClose
  // if(window.location.pathname == "/register") {
  //   style = {
  //     background: "#7A7B7B",
  //   }
  //   // onHide = ""
  // }

  useEffect(() => {
    setShow(props.pagePath == "registration" ? true:false)
  },[props.pagePath])



  return (
    // <div className="App">
    //   <StepWizard transitions="nothing">
    //     <CarrierAdd/>
    //     <DriverAdd/>
    //   </StepWizard>
    // </div>
    <div>
      {/* {window.location.pathname != "/register" ? <button className='btn btn-primary' onClick={handleShow} style={{ borderRadius: '50px', width:130}}>+ Add Carrier</button> : ""} */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Carrier</Modal.Title>
        </Modal.Header>

        <Modal.Body>

            <StepWizard transitions="nothing">
              <CarrierRegistration render={props.render} refresh={props.refresh} setShowAlert={props.setShowAlert} setToastMessage={props.setToastMessage} handleClose={handleClose} modalStatus={true} registration />
                <CarrierEnrollment/>
            </StepWizard>

        </Modal.Body>

        {/* <Modal.Footer> */}
          {/* <Button variant="primary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button variant="primary" onClick={addCarrier}>
            Add Carrier
          </Button> */}
          {/* <Button variant="primary" onClick={props.nextStep}>
            Next Step
          </Button> */}
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
}
