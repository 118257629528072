import React, { useMemo } from 'react';

export default (props) => {
    const data = useMemo(
        () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
        []
    );
    return (
        <div
            className="custom-tooltip"
            style={{
                backgroundColor: 'white',
                padding: '10px 5px 0px 5px',
                border: 'solid 1px lightgrey',
                'border-radius': '5px'
            }}
        >
            <p>
                { data.active || data.Active || data.status }
            </p>
        </div>
    );
};