import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import { Modal, Button, Form, Row, Col, Dropdown } from 'react-bootstrap'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../styles/Carrier.css';
import DriverEdit from './DriverEdit';
import DriverActiveStateRenderer from './DriverActiveStateRenderer';
import DriverGetCompany from './DriverGetCompany';
import DriverAdd from './DriverAdd';
import ToolTip from '../ToolTip';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

function Driver(props) {
    const [rowData, setRowData] = useState([]);
    const [driverData, setDriverData] = useState([])
    const [dataCheck, setDataCheck] = useState(0)
    const gridRef = useRef(null);
    const [fetchEndpoint, setFetchEndpoint] = useState()
    const [pageSize, setPageSize] = useState(10)
    let history = useHistory();
    const { isAuthenticated, userRole, userCompany, userSettings, userId } = useAuth();

    useEffect(() => {
        props.setDriverGridRef(gridRef)
        props.setDriverRefresh(() => () => RefreshTable())
    }, [])

    const render = () => {
        var endpoint = ""
        if (userCompany != undefined) {
            switch (userRole) {
                case 1:
                    endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/Driver/WithCarrier'
                    break;
                case 2:
                    endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/Driver/filtered/' + userCompany
                    break;
                case 3:
                    endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/Driver/filtered/' + userCompany
                    break;
            }
        }

        fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data),
        })
            .then(resp => resp.json())
            .then(resp => setDriverData(resp))
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        render()
    },[render.length, userCompany, userRole])

    function RefreshTable() {
        var endpoint = ""
        if (userCompany != undefined) {
            switch (userRole) {
                case 1:
                    endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/Driver/WithCarrier'
                    break;
                case 2:
                    endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/Driver/filtered/' + userCompany
                    break;
                case 3:
                    endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/Driver/filtered/' + userCompany
                    break;
            }
        }
        fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(resp => resp.json())
            .then(resp => {
                setRowData(
                    resp.map((driver) =>
                    ({
                        firstName: driver.firstName,
                        lastName: driver.lastName,
                        company: driver.carrier.carrierName,
                        driversLicense: driver.driversLicense,
                        consentExpiry: driver.consentExpiry.substring(0, 10),
                        active: driver.active,
                        licensePic: driver.licensePic,
                        driverChange: driver.driverChange,
                        carrier: driver.carrier,
                        carrierId: driver.carrierId,
                        driverForm: driver.driverForm,
                        driverFormFileType: driver.driverFormFileType,
                        licensePicFileType: driver.licensePicFileType,
                        edit: {
                            'driverId': driver.driverId,
                            'firstName': driver.firstName,
                            'lastName': driver.lastName,
                            'driversLicense': driver.driversLicense,
                            'consentExpiry': driver.consentExpiry,
                            'active': driver.active,
                            'licensePic': driver.licensePic,
                            'driverChange': driver.driverChange,
                            'carrier': driver.carrier,
                            'carrierId': driver.carrierId,
                            'driverForm': driver.driverForm,
                        }
                    })
                    )
                )
                gridRef.current.api.refreshCells();
            })
            .catch(error => {
                console.log(error);
            })
    }


    const list = driverData.map((driver) =>
    ({
        firstName: driver.firstName,
        lastName: driver.lastName,
        company: driver.carrier.carrierName,
        driversLicense: driver.driversLicense,
        consentExpiry: driver.consentExpiry.substring(0, 10),
        active: driver.active,
        licensePic: driver.licensePic,
        driverChange: driver.driverChange,
        carrier: driver.carrier,
        carrierId: driver.carrierId,
        driverForm: driver.driverForm,
        driverFormFileType: driver.driverFormFileType,
        licensePicFileType: driver.licensePicFileType,
        edit: {
            'driverId': driver.driverId,
            'firstName': driver.firstName,
            'lastName': driver.lastName,
            'driversLicense': driver.driversLicense,
            'consentExpiry': driver.consentExpiry,
            'active': driver.active,
            'licensePic': driver.licensePic,
            'driverChange': driver.driverChange,
            'carrier': driver.carrier,
            'carrierId': driver.carrierId,
            'driverForm': driver.driverForm,
        }

    })
    )

    // useEffect(() => {
    //     setRowData(list)
    // }, [JSON.stringify(list)])

    useEffect(() => {
        if (list.length > 0 && dataCheck === 0) {
            setRowData(list)
            if(localStorage.getItem("driver") != null) {
                restoreState()
            }
            setDataCheck(1)
        }
    })

    function columnDef() {
        var defs = []
        if (userRole == 1) {
            defs = [
                { field: 'firstName', width: 1000, editable: true },
                { field: 'lastName' },
                {
                    field: 'company',
                },
                {
                    field: 'carrierId',
                    headerName: 'CarrierID',
                    maxWidth: 108
                },
                { field: 'driversLicense' },
                {
                    field: 'consentExpiry',
                    minWidth: 180,
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateAsString = cellValue;
                            var dateParts = dateAsString.split('-');
                            var cellDate = new Date(
                                Number(dateParts[0]),
                                Number(dateParts[1]) - 1,
                                Number(dateParts[2])
                            );

                            if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
                                return 0;
                            }

                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            }

                            if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            }
                        },
                    },
                },
                { field: 'active', maxWidth: 100, floatingFilter: false, cellRenderer: DriverActiveStateRenderer, cellRendererParams: { "refresh": () => RefreshTable(), "role": userRole, "userId": userId }, tooltipField: 'active' },
                { field: 'edit', maxWidth: 100, floatingFilter: false, cellRenderer: DriverEdit, cellRendererParams: { "refresh": () => RefreshTable(), "driverData": driverData, "userId": userId, "userCompany": userCompany } },

            ]
        } else {
            defs = [
                { field: 'firstName', width: 1000, editable: true },
                { field: 'lastName' },
                { field: 'driversLicense' },
                {
                    field: 'consentExpiry',
                    minWidth: 180,
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateAsString = cellValue;
                            var dateParts = dateAsString.split('-');
                            var cellDate = new Date(
                                Number(dateParts[0]),
                                Number(dateParts[1]) - 1,
                                Number(dateParts[2])
                            );

                            if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
                                return 0;
                            }

                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            }

                            if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            }
                        },
                    },
                },
                { field: 'active', maxWidth: 100, floatingFilter: false, cellRenderer: DriverActiveStateRenderer, cellRendererParams: { "refresh": () => RefreshTable(), "role": userRole, "userId":userId }, tooltipField: 'active' },
                { field: 'edit', maxWidth: 100, floatingFilter: false, cellRenderer: DriverEdit, cellRendererParams: { "refresh": () => RefreshTable(), "driverData": driverData, "userId":userId, "userCompany": userCompany } },

            ]
        }
        return defs
    }

    const [columnDefs, setColumnDefs] = useState(columnDef())

    const expire1day = useCallback(() => {
        clearStatus();
        var dateFilterComponent = gridRef.current.api.getFilterInstance('consentExpiry');
        var today = new Date();
        var todayPlus1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString().slice(0, 10);
        dateFilterComponent.setModel({
            type: 'equals',
            dateFrom: todayPlus1,
            dateTo: null,
        });
        gridRef.current.api.onFilterChanged();
    }, []);

    const expire7day = useCallback(() => {
        clearStatus();
        var dateFilterComponent = gridRef.current.api.getFilterInstance('consentExpiry');
        var today = new Date();
        var todayPlus7 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 8).toISOString().slice(0, 10);
        dateFilterComponent.setModel({
            condition1: {
                type: 'greaterThan',
                dateFrom: today.toISOString().slice(0, 10),
                dateTo: null,
            },
            operator: 'AND',
            condition2: {
                type: 'lessThan',
                dateFrom: todayPlus7,
                dateTo: null,
            },
        });
        gridRef.current.api.onFilterChanged();
    }, []);

    const expire30day = useCallback(() => {
        clearStatus();
        var dateFilterComponent = gridRef.current.api.getFilterInstance('consentExpiry');
        var today = new Date();
        var todayPlus30 = new Date(new Date().setDate(today.getDate() + 31)).toISOString().slice(0, 10);
        dateFilterComponent.setModel({
            condition1: {
                type: 'greaterThan',
                dateFrom: today.toISOString().slice(0, 10),
                dateTo: null,
            },
            operator: 'AND',
            condition2: {
                type: 'lessThan',
                dateFrom: todayPlus30,
                dateTo: null,
            },
        });
        gridRef.current.api.onFilterChanged();
    }, []);

    const expireAll = useCallback(() => {
        clearStatus();
        var dateFilterComponent = gridRef.current.api.getFilterInstance('consentExpiry');
        var today = new Date().toISOString().slice(0, 10);
        dateFilterComponent.setModel({
            type: 'lessThan',
            dateFrom: today,
            dateTo: null,
        });
        gridRef.current.api.onFilterChanged();
    }, []);

    const showStatusChanged = useCallback(() => {
        gridRef.current.api.getFilterInstance('consentExpiry').setModel({});
        var statusFilter = gridRef.current.api.getFilterInstance('active');
        statusFilter.setModel({
            type: 'equals',
            filter: "Status Changed",
        });
        gridRef.current.api.onFilterChanged();
    }, []);

    const queryParams = new URLSearchParams(window.location.search);
    const status_changed = queryParams.get("status_changed") != undefined ? queryParams.get("status_changed") : ""
    if (status_changed == "true" && rowData.length > 0) {
        var statusFilter = gridRef.current.api.getFilterInstance('active');
        statusFilter.setModel({
            type: 'equals',
            filter: "Status Changed",
        });
        gridRef.current.api.onFilterChanged();

        const queryParams = ""
        history.replace({
            search: queryParams,
            pathname: "/",
        })
    }

    const queryParamsCompany = new URLSearchParams(window.location.search);
    const company_filter = queryParamsCompany.get("company") != undefined ? queryParamsCompany.get("company") : ""
    if (company_filter.length > 0 && rowData.length > 0) {
        var statusFilter = gridRef.current.api.getFilterInstance('company');
        statusFilter.setModel({
            type: 'contains',
            filter: company_filter,
        });
        gridRef.current.api.onFilterChanged();

        const queryParamsCompany = ""
        history.replace({
            search: queryParamsCompany,
            pathname: "/",
        })
    }


    const clear = useCallback(() => {
        var dateFilterComponent = gridRef.current.api.getFilterInstance('consentExpiry');
        var statusFilter = gridRef.current.api.getFilterInstance('active');
        statusFilter.setModel({});
        dateFilterComponent.setModel({});
        gridRef.current.api.onFilterChanged();
    }, []);


    const defaultColDef = useMemo(() => {

        return {
            flex: 1,
            filter: true,
            floatingFilter: true,
            sortable: true,
            resizable: true,
            menuTabs: ['columnsMenuTab'],
            columnsMenuParams: {
                // suppresses updating the layout of columns as they are rearranged in the grid
                suppressSyncLayoutWithGrid: true,
            },
            tooltipComponent: ToolTip,
        };
    }, []);

    const paginationNumberFormatter = useCallback(function (params) {
        return '[' + params.value.toLocaleString() + ']';
    }, []);


    const onPageSizeChanged = useCallback((event) => {
        var value = event.target.value;

        gridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.carrierGridRef != 'undefined') props.carrierGridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.userGridRef != 'undefined') props.userGridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.auditLogGridRef != 'undefined') props.auditLogGridRef.current.api.paginationSetPageSize(Number(value));
        localStorage.setItem('page', value);
        props.setPage(value)
        const settings = {
            "page": value
        }

        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/UserSetting/update/' + userSettings, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(settings),
        })
            .catch(error => {
                console.log(error);
            })
    }, [props]);

    const saveState = useCallback(() => {
        window.colState = gridRef.current.columnApi.getColumnState();
        var data = JSON.stringify(gridRef.current.columnApi.getColumnState());
        localStorage.setItem('driver', data);

        const settings = {
            "driverCol": data
        }

        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/UserSetting/update/' + userSettings, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(settings),
        })
            //.then(resp => {
            //    if (resp.status == 200) {
            //        PostAuditLog("User Edit", "User edit successful.")
            //    } else {
            //        PostAuditLog("User Edit", "User edit failed.")
            //    }
            //})
            .catch(error => {
                console.log(error);
            })
    }, []);


    const restoreState = useCallback(() => {
        gridRef.current.columnApi.applyColumnState({
            state: JSON.parse(localStorage['driver']),
            applyOrder: true,
        });
    }, []);

    const resetState = useCallback(() => {
        gridRef.current.columnApi.resetColumnState();
        gridRef.current.columnApi.applyColumnState({
            state: [
                { colId: 'Account', sort: 'asc', sortIndex: 0 },
            ],
            defaultState: { sort: null },
        });
    }, []);

    const clearFilters = useCallback(() => {
        gridRef.current.api.setFilterModel(null);
        gridRef.current.api.onFilterChanged();
    }, []);

    const clearStatus = useCallback(() => {
        gridRef.current.api.getFilterInstance('active').setModel({});
        gridRef.current.api.onFilterChanged();
    }, []);

    const handlePageSize = (e) => {
        gridRef.current.api.paginationSetPageSize(Number(e.target.value));
    }

    const onGridReady = useCallback((params) => {
        restoreState()
    }, []);

    return (
        // <div className="ag-theme-alpine ag-table-size" style={{ width: '100%', height: 590 }}>
        <div className="ag-theme-alpine ag-table-size" style={{ width: '100%', height: '100%' }}>

            <div style={{ 'padding': '10px 10px', 'backgroundColor': 'white', display: 'flex', justifyContent: 'space-between' }} className="carrier-buttons mobile-button">


                <div style={{ display: 'flex', gap: '10px' }} className="buttons all-buttons">
                    <DriverAdd refresh={() => RefreshTable()} userId={userId} role={userRole} userCompany={userCompany} />
                    {/* {userRole != undefined && userRole == "2" ? <DriverAdd refresh={() => RefreshTable()} /> : <DriverAdd style={{visibility: "hidden!important"}} refresh={() => RefreshTable()} disabled />} */}
                    {/* {userRole != undefined && userRole == "2" ? <DriverAdd refresh={() => RefreshTable()} /> : <DriverAdd style={{visibility: "hidden"}} refresh={() => RefreshTable()} />} */}
                    <span>
                        <Dropdown>
                            <Dropdown.Toggle style={{ borderRadius: 50, padding: '6px 13px 6px 25px' }} id="dropdown-basic" className="carrier-buttons column-btn">
                                Column Settings
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={saveState} href="#">Save</Dropdown.Item>
                                <Dropdown.Item onClick={restoreState} href="#">Restore</Dropdown.Item>
                                <Dropdown.Item onClick={resetState} href="#">Reset</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>

                    <button className='btn btn-primary clear-btn' onClick={clearFilters} style={{ borderRadius: '50px', padding: '6px 15px 6px 15px' }}>Clear Filters</button>
                    <button className='btn btn-primary status-btn' onClick={showStatusChanged} style={{ borderRadius: '50px', padding: '6px 20px 6px 20px' }}>Show Status Changed</button>
                </div>

                <div className="form-filer">
                    <span className="filter-section" >
                        <span className="filter-label"> Forms Expiring in: </span>
                        <button onClick={expire1day} className='btn btn-filter'>1 Day</button>
                        <button onClick={expire7day} className='btn btn-filter'>7 Days</button>
                        <button onClick={expire30day} className='btn btn-filter'>30 Days</button>
                        <button onClick={expireAll} className='btn btn-filter'>All</button>
                        {/* <button onClick={showStatusChanged} className='btn btn-filter'>Filter by Status Changed - REMOVE ME</button> */}
                        <button onClick={clear} className='btn btn-danger'>Clear</button>
                    </span>
                </div>
            </div>


            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={props.page}
                paginationNumberFormatter={paginationNumberFormatter}
                onGridReady={onGridReady}
                domLayout={'autoHeight'}
            >

            </AgGridReact>

            <div className="page-size-div">
                Page Size:
                <select value={props.page} onChange={onPageSizeChanged} id="page-size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
            </div>

        </div>

    );
}

export default Driver;
