import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Row, Col, Alert } from 'react-bootstrap'



function AccessBody(props) {
    return (
        <div className='auth-body'>
            <h2>{props.header}</h2>
            <p>{props.body}</p>
        </div>
    );
}


export default AccessBody;
