import React, { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import { BrowserRouter, Layout, Route } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { AuthProvider } from "./context/AuthContext";

import './styles/index.css';

import Body from './components/Body';
import Footer from './components/Footer';
import Notification from './components/Notification';
import Carrier from './components/Carrier/Carrier';
import Driver from './components/Driver/Driver';
import TableBar from './components/TableBar';
import Upload from './components/Upload';
import Download from './components/Download';
import Register from './components/Register';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');



ReactDOM.render(
    <AuthProvider>
        <BrowserRouter basename={baseUrl}>
            <Body />
            {/* <Route path='/register' component={Register}/> */}
        </BrowserRouter>
    </AuthProvider>,
    rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
