// import './Header.css';
import React, { useState, useMemo, useCallback, useRef } from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap'
import '../../styles/index.css';



function CarrierNameRenderer(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;



  

  return (
    <>
        {/* <a className="carrier-name-link" href="drivers?company={}">{cellValue}</a> */}
        <a className="carrier-name-link" href={"drivers?company=" + cellValue}>{cellValue}</a>
    </>
  );
}

export default CarrierNameRenderer;
