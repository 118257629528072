import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import { Modal, Button, Form, Row, Col, Dropdown } from 'react-bootstrap'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../styles/Carrier.css';
import CarrierEdit from './CarrierEdit';
import CarrierNameRenderer from './CarrierNameRenderer';
import ToolTip from '../ToolTip';
import CarrierWizard from './CarrierWizard';
import CarrierAdd from './CarrierAdd'
import CarrierActiveStateRenderer from './CarrierActiveStateRenderer';
import { useAuth } from '../../context/AuthContext';



function Carrier(props) {
    // const [rowData, setRowData] = useState(carrierData);
    const gridRef = useRef(null);
    
    const [carrierData, setCarrierData] = useState([])
    const [dataCheck, setDataCheck] = useState(0)
    decodeURI(carrierData);
    const [rowData, setRowData] = useState([]);
    const { isAuthenticated, userRole, userId, userSettings, user } = useAuth();
    const [fetchEndpoint, setFetchEndpoint] = useState();
    const [columnSettings, setColumnSettings] = useState([]);

    useEffect(() => {
        props.setCarrierGridRef(gridRef)
        props.setCarrierRefresh(() => () => RefreshTable())
    }, [])

    const render = () => {
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Credentials': 'true',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            // body: JSON.stringify(data),
        })

            .then(resp => resp.json())
            .then(resp => setCarrierData(resp))
            .catch(error => {
                console.log(error);
            })
    }

    function RefreshTable() {
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
        })
            .then(resp => resp.json())
            .then(resp => {
                setRowData(
                    resp.map((carrier) =>
                    ({
                        carrierName: carrier.carrierName,
                        address: carrier.address,
                        email: carrier.email,
                        phone: carrier.phone,
                        status: carrier.status,
                        carrierId: carrier.carrierId,

                        statusData: {
                            'id': carrier.carrierId,
                            'name': carrier.carrierName,
                            'address': carrier.address,
                            'postalCode': carrier.postalCode,
                            'citys': carrier.city,
                            'province': carrier.province,
                            'email': carrier.email,
                            'phone': carrier.phone,
                            'status': carrier.status,
                            'refresh': () => RefreshTable()
                        },
                        edit: {
                            'id': carrier.carrierId,
                            'name': carrier.carrierName,
                            'address': carrier.address,
                            'postalCode': carrier.postalCode,
                            'citys': carrier.city,
                            'province': carrier.province,
                            'email': carrier.email,
                            'phone': carrier.phone
                        }
                    })
                    )
                )
                gridRef.current.api.refreshCells();
            })
            .catch(error => {
                console.log(error);
            })
    }

    


    useEffect(() => {
        render()
    }, [render.length, userRole, userId])

    // useEffect(() => {
    //     render()
    // }
    //     , [JSON.stringify(carrierData)])

    const list = carrierData.map((carrier) =>
    ({
        carrierName: carrier.carrierName,
        address: carrier.address,
        email: carrier.email,
        phone: carrier.phone,
        carrierId: carrier.carrierId,
         status: carrier.status,
        statusData: {
            'id': carrier.carrierId,
            'name': carrier.carrierName,
            'address': carrier.address,
            'postalCode': carrier.postalCode,
            'citys': carrier.city,
            'province': carrier.province,
            'email': carrier.email,
            'phone': carrier.phone,
            'status': carrier.status,
            'refresh': () => RefreshTable()
        },
        edit: {
            'id': carrier.carrierId,
            'name': carrier.carrierName,
            'address': carrier.address,
            'postalCode': carrier.postalCode,
            'citys': carrier.city,
            'province': carrier.province,
            'email': carrier.email,
            'phone': carrier.phone,
            'carrierAgreement': carrier.carrierAgreement,
            'refresh': () => RefreshTable()
        }

    })
    )

    useEffect(() => {
        if (list.length > 0 && dataCheck === 0) {
            setRowData(list)
            if (localStorage.getItem("carrier") != null) {
                restoreState()
            }
            setDataCheck(1)
        }
    })

    const statusValueFormatter = params => {
        const status = params;
        return status;
    };

    function columnDef() {
        var defs = []
        defs = [
            {
                field: 'carrierId',
                headerName: 'CarrierID',
                maxWidth: 108,
                suppressSizeToFit: true,
            },
            { field: 'carrierName', maxWidth: 1000, cellRenderer: CarrierNameRenderer, },
            { field: 'address' },
            { field: 'email' },
            { field: 'phone' },
            {
                field: 'status',
                valueFormatter: statusValueFormatter,
                maxWidth: 100,
                floatingFilter: false,
                cellRenderer: CarrierActiveStateRenderer,
                tooltipField: 'status',
                cellRendererParams: {
                    "refresh": () => RefreshTable(),
                    "role": userRole,
                    "userId": userId
                },
                filterParams: {
                    valueFormatter: statusValueFormatter,
                }

            },
            { field: 'edit', maxWidth: 100, floatingFilter: false, cellRenderer: CarrierEdit, cellRendererParams: { "refresh": () => RefreshTable(), "userId": userId, 'carrierData': carrierData } },
            // { field: 'edit', maxWidth: 100, floatingFilter: false, cellRenderer:  CarrierAdd },
        ]

        return defs
    }

    const [columnDefs, setColumnDefs] = useState(columnDef())

    // const [columnDefs, setColumnDefs] = useState([
    //     {
    //         field: 'carrierId',
    //         headerName: 'CarrierID',
    //         maxWidth: 108,
    //         suppressSizeToFit: true,
    //     },
    //     { field: 'carrierName', maxWidth: 1000, cellRenderer: CarrierNameRenderer, },
    //     { field: 'address' },
    //     { field: 'email' },
    //     { field: 'phone' },
    //     { field: 'status', maxWidth: 100, floatingFilter: false, cellRenderer: CarrierActiveStateRenderer, tooltipField: 'status', cellRendererParams: { "refresh": () => RefreshTable(), "role": userRole, "userId": userId } },
    //     { field: 'edit', maxWidth: 90, floatingFilter: false, cellRenderer: CarrierEdit, cellRendererParams: { "refresh": () => RefreshTable(), "userId": userId, 'carrierData': carrierData} },
    //     // { field: 'edit', maxWidth: 100, floatingFilter: false, cellRenderer:  CarrierAdd },
    // ])



    const defaultColDef = useMemo(() => {

        return {
            flex: 1,
            width: 100,
            filter: true,
            floatingFilter: true,
            sortable: true,
            resizable: true,
            menuTabs: ['columnsMenuTab'],
            columnsMenuParams: {
                // suppresses updating the layout of columns as they are rearranged in the grid
                suppressSyncLayoutWithGrid: true,
            },
            tooltipComponent: ToolTip,
        };
    }, []);

    const paginationNumberFormatter = useCallback(function (params) {
        return '[' + params.value.toLocaleString() + ']';
    }, []);

    const saveState = useCallback(() => {
        window.colState = gridRef.current.columnApi.getColumnState();
        var data = JSON.stringify(gridRef.current.columnApi.getColumnState());
        localStorage.setItem('carrier', data);
        console.log('column state saved');
        

        const settings = {
            "carrierCol": data
        }

        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/UserSetting/update/' + userSettings, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(settings),
        })
            //.then(resp => {
            //    if (resp.status == 200) {
            //        PostAuditLog("User Edit", "User edit successful.")
            //    } else {
            //        PostAuditLog("User Edit", "User edit failed.")
            //    }
            //})
            .catch(error => {
                console.log(error);
            })

    }, []);

    const restoreState = useCallback(() => {
        // if (!window.colState || !localStorage['col']) {
        //   console.log('no columns state to restore by, you must save state first');
        //   return;
        gridRef.current.columnApi.applyColumnState({
            //   state: window.colState,
            state: JSON.parse(localStorage['carrier']),
            applyOrder: true,
        });
    }, []);

    const resetState = useCallback(() => {

        gridRef.current.columnApi.resetColumnState();
        gridRef.current.columnApi.applyColumnState({
            state: [
                { colId: 'Account', sort: 'asc', sortIndex: 0 },
            ],
            defaultState: { sort: null },
        });
        console.log('column state reset');
    }, []);

    const clearFilters = useCallback(() => {
        gridRef.current.api.setFilterModel(null);
        gridRef.current.api.onFilterChanged();
    }, []);

    const onGridReady = useCallback((params) => {
        restoreState()
        gridRef.current.columnApi.autoSizeColumns()
    }, []);

    // const onFirstDataRendered = useCallback((params) => {
    //    gridRef.current.api.sizeColumnsToFit();
    // }, []);


    // useEffect(() => {
    //    restoreState()
    // }, [props.settingsConfigured])


    const onPageSizeChanged = useCallback((event) => {
        var value = event.target.value;
        gridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.driverGridRef != 'undefined') props.driverGridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.userGridRef != 'undefined') props.userGridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.auditLogGridRef != 'undefined') props.auditLogGridRef.current.api.paginationSetPageSize(Number(value));
        localStorage.setItem('page', value);
        props.setPage(value)
        //setPage(event.target.value);
        const settings = {
            "page": value
        }

        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/UserSetting/update/' + userSettings, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(settings),
        })
            .catch(error => {
                console.log(error);
            })
    }, [props]);

    // const gridOptions = {
    //     onRowDataUpdated: onRowDataUpdated() 
    // }

    // function onRowDataUpdated() {
    //     gridRef.current.api.sizeColumnsToFit();
    // }

    return (


        // <div className="ag-theme-alpine ag-table-size" style={{ width: '100%', height: 590 }}>
        <div className="ag-theme-alpine ag-table-size" style={{ width: '100%', height: '100%' }}>
            <div style={{ 'padding': '10px 10px', 'backgroundColor': 'white' }} className="carrier-buttons mobile-button">
                <div style={{ display: 'flex', gap: '10px' }} className="buttons all-buttons">
                    {userRole != undefined && userRole == "1" ? <CarrierAdd render={render} refresh={() => RefreshTable()} modalStatus={false} userId={userId}/> : <></>}
                    <span>
                        <Dropdown>
                            <Dropdown.Toggle style={{ borderRadius: 50, padding: '6px 13px 6px 25px' }} id="dropdown-basic" className="carrier-buttons column-btn">
                                Column Settings
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={saveState} href="#">Save</Dropdown.Item>
                                <Dropdown.Item onClick={restoreState} href="#">Restore</Dropdown.Item>
                                <Dropdown.Item onClick={resetState} href="#">Reset</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>
                    <button className='btn btn-primary dropdown-toggle btn btn-primary clear-btn' onClick={clearFilters} style={{ borderRadius: '50px', padding: '6px 15px 6px 15px' }}>Clear Filters</button>
                </div>
            </div>

            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={props.page}
                paginationNumberFormatter={paginationNumberFormatter}
                domLayout={'autoHeight'}
                onGridReady={onGridReady}
                // gridOptions={gridOptions}
                // onFirstDataRendered={onFirstDataRendered}
            >

            </AgGridReact>

            <div className="page-size-div">
                Page Size:
                <select value={props.page} onChange={onPageSizeChanged} id="page-size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
            </div>
        </div>

    );
}

export default Carrier;
