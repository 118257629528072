// import './Header.css';
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import {Modal, Button, Form, Row, Col, Dropdown, Alert, Toast} from 'react-bootstrap'
import { BrowserRouter, Layout, useHistory } from 'react-router-dom';


export default function CarrierWizard(props) {

  const [show, setShow] = useState(props.pagePath == "login" ? true:false);
  const handleClose = () => {setShow(false)};
  const handleShow = () => { setShow(true);  }

  useEffect(() => {
    setShow(props.pagePath == "login" ? true:false)
  },[props.pagePath])

  function navigateToRegistration() {
    props.setPagePath("registration")
  }

  return (
    <div>
      {/* <Modal show={show} onHide={handleClose} size="sm" centered> */}
      <Modal show={show} size="sm" centered>
      
        <Modal.Header >
          <Modal.Title>Portal Login</Modal.Title>
        </Modal.Header>
          {/* <Modal.Body className="login-modal"> */}
          <Modal.Body >
            <div className="login-modal">
              <Button variant="primary" className="login-button" href={process.env.REACT_APP_HOST_AUTH_BACKEND + '/auth/login'}>Login</Button>{' '}
              <Button variant="primary" className="login-button" onClick={() => navigateToRegistration()}>Register</Button>{' '}
            </div>
          </Modal.Body>
          
      </Modal>
    </div>
  );
}
