// import './Header.css';
import React, { useState, useMemo, useCallback, useRef } from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap'
import '../../styles/index.css';



function CarrierEnrollment(props) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setShow(true);  }
  const [carrier, setCarrier] = useState([]);
  const [update, setUpdate] = useState();
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  const [carrierId, setCarrierId] = useState();
  const [carrierName, setCarrierName] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [postalCode, setPostalCode] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [status, setStatus] = useState();
  const [carrierAgreement, setCarrierAgreement] = useState();


  const addCarrier = async (event) =>{
    event.preventDefault();
    // const currentDate = (new Date()).toISOString().split('T')[0]

    const data = { 
      "carrierId": carrierId,
      "carrierName": carrierName,
      "email": email,
      "phone": phone,
      "status": status,
      "address": address1,
      "address2": address2,
      "city": city,
      "province": province,
      "postalCode": postalCode,
      "carrierAgreement": carrierAgreement
    }


    await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier', {
          method: 'POST',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json',
              // 'Authorization': `Token ${token['mytoken']}`,
          },
          body: JSON.stringify(data),
    })
    .then(resp => resp.json())
    .then(resp => setUpdate(resp))
    .catch(error => {
        console.log(error);
    })
    // getCustomerNotes()
    // setNote('')
    
    setShow(false);
    }


  

  return (
    <>
      {/* <a onClick={handleShow} style={{cursor: 'pointer'}}>Add Carrier<i className="las la-plus-alt plus" aria-hidden="true"></i></a> */}
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} >
            <Form.Label>Upload Enrollment Form</Form.Label>
            <Form.Control type="file" />
          </Form.Group>

          <Form.Group as={Col} >
            <Form.Label>Download Enrollment Form</Form.Label>
            <Button variant="primary">
              Download
            </Button>
          </Form.Group>
        </Row>
      </Form>
      <Button variant="primary" onClick={props.previousStep}>
        Previous Step
      </Button>
      {/* <Button variant="primary" onClick={addCarrier}>
        Save Carrier
      </Button> */}
      {/* <Button variant="primary" onClick={props.nextStep}>
        Next Step
      </Button> */}
    </>
  );
}

export default CarrierEnrollment;
