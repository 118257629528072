import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import { Modal, Button, Form, Row, Col, Dropdown } from 'react-bootstrap'
import AuditLogGetEmail from './AuditLogGetEmail';
import AuditLogGetCompany from './AuditLogGetCompany';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import PostAuditLog from '../../utils/Fetches'
import '../../styles/Carrier.css';
import { useAuth } from '../../context/AuthContext';

// import CarrierActiveStateRenderer from './CarrierActiveStateRenderer';

function AuditLog(props) {
    // const [rowData, setRowData] = useState(carrierData);
    const gridRef = useRef(null);
    const [auditData, setAuditData] = useState([])
    const [dataCheck, setDataCheck] = useState(0)
    decodeURI(auditData);
    const [rowData, setRowData] = useState([]);
    const { isAuthenticated, userRole, userCompany, userSettings, userId } = useAuth();

    useEffect(() => {
        props.setAuditLogGridRef(gridRef)
        props.setAuditLogRefresh(() => () => RefreshTable())
    }, [])

    const render = () => {
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Log/WithUser', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            // body: JSON.stringify(data),
        })

            .then(resp => resp.json())
            .then(resp => setAuditData(resp))

            .catch(error => {
                console.log(error);
            })
    }

    function RefreshTable() {
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Log/WithUser', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            // body: JSON.stringify(data),
        })

            .then(resp => resp.json())
            .then(resp => setRowData(
                resp.map((audit) =>
                    ({
                        Email: audit.user.email,
                        Company: audit.user.carrier.carrierName,
                        Action: audit.action,
                        Status: audit.status,
                        Date: audit.date.substring(0, 10),
                    })
                )
            ))
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        render()
    }
        , [JSON.stringify(auditData)])

    const list = auditData.map((audit) =>
    ({
        Email: audit.user.email,
        Company: audit.user.carrier.carrierName,
        Action: audit.action,
        Status: audit.status,
        Date: audit.date.substring(0, 10),
    })
    )

    // var restoreStateCheck = false;
    // if(localStorage.getItem("carrier") != null && restoreStateCheck == false) {
    //   restoreState()
    //   restoreStateCheck = true;
    // }

    useEffect(() => {
        if (list.length > 0 && dataCheck === 0) {
            setRowData(list)
            if (localStorage.getItem("log") != null) {
                restoreState()
            }
            setDataCheck(1)
        }
    })

    // const [rowData] = useState([
    //     { Email: "mikemichaels@email.com", Company: "Canadian National Transportation Limited", Action: "Updated a driver", Status: "Driver update failed", Date: "2022-03-01" },
    //     { Email: "jakekingston@email.com", Company: "Canadian National Transportation Limited", Action: "Created a user", Status: "User creation succesful", Date: "2022-04-03" },
    //   {Email: "mikemichaels@email.com", Company: "Canadian National Transportation Limited", Action: "Updated a carrier", Status: "Carrier update succesful",  Date: "2022-04-05" },
    //   {Email: "kevinowens@email.com", Company: "Dr. Hook Towing Services Ltd.", Action: "Updated a driver", Status: "Driver update succesful",  Date: "2022-04-22" },
    //   {Email: "mikemichaels@email.com", Company: "Canadian National Transportation Limited", Action: "Updated a driver", Status: "Driver update failed",  Date: "2022-05-02" },
    //   {Email: "kevinowens@email.com", Company: "Dr. Hook Towing Services Ltd.", Action: "Updated a carrier", Status: "Carrier update failed",  Date: "2022-05-03" },
    //   {Email: "mikemichaels@email.com", Company: "Canadian National Transportation Limited", Action: "Created a user", Status: "User creation sucessful",  Date: "2022-05-08" },
    //   {Email: "mikemichaels@email.com", Company: "Canadian National Transportation Limited", Action: "Updated a carrier", Status: "Carrier update succesful",  Date: "2022-05-23" },
    //   {Email: "jonathanbuck@email.com", Company: "Bison Transport Inc.", Action: "Updated a driver", Status: "Driver update failed",  Date: "2022-06-01" },
    //   {Email: "mikemichaels@email.com", Company: "Canadian National Transportation Limited", Action: "Updated a carrier", Status: "Carrier update failed",  Date: "2022-06-11" },
    //   {Email: "mikemichaels@email.com", Company: "Canadian National Transportation Limited", Action: "Created a user", Status: "User Creation failed",  Date: "2022-06-14" },
    // ]);

    const [columnDefs, setColumnDefs] = useState([
        { field: 'Email', maxWidth: 1000 },
        { field: 'Company', maxWidth: 1000 },
        { field: 'Action', maxWidth: 1000 },
        { field: 'Status', maxWidth: 1000 },
        {
            field: 'Date',
            minWidth: 180,
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                    var dateAsString = cellValue;
                    var dateParts = dateAsString.split('-');
                    var cellDate = new Date(
                        Number(dateParts[0]),
                        Number(dateParts[1]) - 1,
                        Number(dateParts[2])
                    );

                    if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
                        return 0;
                    }

                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    }

                    if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                },
            },
        },

    ])


    

    const defaultColDef = useMemo(() => {

        return {
            flex: 1,
            filter: true,
            floatingFilter: true,
            sortable: true,
            resizable: true,
            menuTabs: ['columnsMenuTab'],
            columnsMenuParams: {
                // suppresses updating the layout of columns as they are rearranged in the grid
                suppressSyncLayoutWithGrid: true,
            },
        };
    }, []);

    const paginationNumberFormatter = useCallback(function (params) {
        return '[' + params.value.toLocaleString() + ']';
    }, []);

    // const onGridReady = useCallback((params) => {
    //   fetch('http://www.ag-grid.com/example-assets/olympic-winners.json')
    //     .then((resp) => resp.json())
    //     .then((data) => {
    //       setRowData(data);
    //     });
    // }, []);

    const onPageSizeChanged = useCallback((event) => {
        var value = event.target.value;
        gridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.carrierGridRef != 'undefined') props.carrierGridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.driverGridRef != 'undefined') props.driverGridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.userGridRef != 'undefined') props.userGridRef.current.api.paginationSetPageSize(Number(value));
        localStorage.setItem('page', value);
        props.setPage(value)
        const settings = {
            "page": value
        }

        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/UserSetting/update/' + userSettings, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(settings),
        })
            .catch(error => {
                console.log(error);
            })
    }, [props]);

    const exportAsCSV = useCallback(() => {
        let exportDate = new Date()
        let exportName = "audit-log-" + exportDate.toISOString().split('T')[0]
        gridRef.current.api.exportDataAsCsv({ fileName: exportName });

        PostAuditLog("CSV Export", "CSV export succesful.", userId)
    }, []);

    const saveState = useCallback(() => {
        window.colState = gridRef.current.columnApi.getColumnState();
        var data = JSON.stringify(gridRef.current.columnApi.getColumnState());
        localStorage.setItem('log', data);
        console.log('column state saved');
        const settings = {
            "logCol": data
        }
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/UserSetting/update/' + userSettings, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(settings),
        })
            //.then(resp => {
            //    if (resp.status == 200) {
            //        PostAuditLog("User Edit", "User edit successful.")
            //    } else {
            //        PostAuditLog("User Edit", "User edit failed.")
            //    }
            //})
            .catch(error => {
                console.log(error);
            })
    }, []);


    const restoreState = useCallback(() => {
        gridRef.current.columnApi.applyColumnState({
            //   state: window.colState,
            state: JSON.parse(localStorage['log']),
            applyOrder: true,
        });
    }, []);

    const resetState = useCallback(() => {

        gridRef.current.columnApi.resetColumnState();
        gridRef.current.columnApi.applyColumnState({
            state: [
                { colId: 'Account', sort: 'asc', sortIndex: 0 },
            ],
            defaultState: { sort: null },
        });
        console.log('column state reset');
    }, []);

    const date1Day = useCallback(() => {
        var dateFilterComponent = gridRef.current.api.getFilterInstance('Date');
        var today = new Date();
        var todayPlus1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString().slice(0, 10);
        dateFilterComponent.setModel({
            type: 'equals',
            dateFrom: todayPlus1,
            dateTo: null,
        });
        gridRef.current.api.onFilterChanged();
    }, []);

    const date7Day = useCallback(() => {
        var dateFilterComponent = gridRef.current.api.getFilterInstance('Date');
        var today = new Date();
        var todayPlus7 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 8).toISOString().slice(0, 10);
        dateFilterComponent.setModel({
            condition1: {
                type: 'greaterThan',
                dateFrom: today.toISOString().slice(0, 10),
                dateTo: null,
            },
            operator: 'AND',
            condition2: {
                type: 'lessThan',
                dateFrom: todayPlus7,
                dateTo: null,
            },
        });
        gridRef.current.api.onFilterChanged();
    }, []);

    const date30Day = useCallback(() => {
        var dateFilterComponent = gridRef.current.api.getFilterInstance('Date');
        var today = new Date();
        var todayPlus30 = new Date(new Date().setDate(today.getDate() + 31)).toISOString().slice(0, 10);
        dateFilterComponent.setModel({
            condition1: {
                type: 'greaterThan',
                dateFrom: today.toISOString().slice(0, 10),
                dateTo: null,
            },
            operator: 'AND',
            condition2: {
                type: 'lessThan',
                dateFrom: todayPlus30,
                dateTo: null,
            },
        });
        gridRef.current.api.onFilterChanged();
    }, []);

    const dateAll = useCallback(() => {
        var dateFilterComponent = gridRef.current.api.getFilterInstance('Date');
        var today = new Date().toISOString().slice(0, 10);
        dateFilterComponent.setModel({
            type: 'lessThan',
            dateFrom: today,
            dateTo: null,
        });
        gridRef.current.api.onFilterChanged();
    }, []);

    const clearFilters = useCallback(() => {
        gridRef.current.api.setFilterModel(null);
        gridRef.current.api.onFilterChanged();
    }, []);

    const handlePageSize = (e) => {
        gridRef.current.api.paginationSetPageSize(Number(e.target.value));
    }

    return (
        <div className="ag-theme-alpine ag-table-size" style={{ width: '100%', height: '100%' }}>
            <div style={{ 'padding': '10px 10px', 'backgroundColor': 'white' }} className="carrier-buttons">
                <div style={{ display: 'flex', gap: '10px' }} className="buttons all-buttons">

                    <button className='btn btn-primary' style={{ borderRadius: '50px', width: 130 }} disabled>+ Add Log</button>
                    <span>
                        <Dropdown>
                            <Dropdown.Toggle style={{ borderRadius: 50, padding: '6px 13px 6px 25px' }} id="dropdown-basic" className="carrier-buttons column-btn">
                                Column Settings
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={saveState} href="#">Save</Dropdown.Item>
                                <Dropdown.Item onClick={restoreState} href="#">Restore</Dropdown.Item>
                                <Dropdown.Item onClick={resetState} href="#">Reset</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>
                    <button className='btn btn-primary' onClick={clearFilters} style={{ borderRadius: '50px', padding: '6px 15px 6px 15px' }}>Clear Filters</button>
                    <Button variant="primary" style={{ borderRadius: 50, paddingLeft: 25, paddingRight: 25 }} onClick={exportAsCSV}>
                        Export as CSV
                    </Button>
                    {/* <span className="filter-section">

              <span className="filter-label"> Dates: </span>
              <button onClick={date1Day}  className='btn btn-filter'>1 Day</button>
              <button onClick={date7Day} className='btn btn-filter'>7 Days</button>
              <button onClick={date30Day}  className='btn btn-filter'>30 Days</button>
              <button onClick={dateAll}  className='btn btn-danger'>Clear Filters</button>

          </span> */}


                    {/* <button onClick={RefreshTable}>*/}
                    {/*    Refresh All*/}
                    {/*</button> */}
                </div>
            </div>

            {/* <TableBar /> */}


            {/* <div style={{ 'padding': '10px 10px', 'backgroundColor': 'white' }}>
                <CarrierWizard render={render} refresh={RefreshTable} />
                <UserAdd refreshTable={""}/>
                <button onClick={RefreshTable}>
                    Refresh All
                </button>
            </div> */}


            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={props.page}
                paginationNumberFormatter={paginationNumberFormatter}
                style={{ width: '100%', height: '200%;' }}
                domLayout={'autoHeight'}
            // onGridReady={onGridReady}
            >

            </AgGridReact>

            <div className="page-size-div">
                Page Size:
                <select value={props.page} onChange={onPageSizeChanged} id="page-size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
            </div>

            {/* <CarrierWizard render={render} refresh={RefreshTable} /> */}

            {/* <CarrierAdd /> */}
            {/* <CarrierWizard /> */}

        </div>

    );
}

export default AuditLog;
