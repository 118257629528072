import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Modal, Button, Form, Row, Col, Dropdown } from 'react-bootstrap'
import '../../styles/Carrier.css';
// import CarrierEdit from './CarrierEdit';
import UserEdit from './UserEdit';
import UserRoleRender from './UserRoleRender';
import UserGetCompany from './UserGetCompany';
import UserActiveStateRenderer from './UserActiveStateRenderer';
import UserAdd from './UserAdd';
import ToolTip from '../ToolTip';
import { useAuth } from '../../context/AuthContext';

function User(props) {
    const [userData, setUserData] = useState([]);
    const [dataCheck, setDataCheck] = useState(0)
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const { isAuthenticated, userRole, userCompany, userId, userSettings } = useAuth();
    const [localUserRole, setLocalUserRole] = useState(userRole != "" ? userRole : "");
    const [localUserCompany, setLocalUserCompany] = useState(userCompany != "" ? userCompany : "");
    const [localUserId, setLocalUserId] = useState(userId != "" ? userId : "");

    useEffect(() => {
        props.setUserGridRef(gridRef)
        props.setUserRefresh(() => () => RefreshTable())
    }, [])
    
    decodeURI(userData);
    const render = () => {
        var endpoint = ""

        switch (userRole) {
            case 1:
                endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/User/WithCarrier'
                break;
            case 2:
                endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/User/filtered/' + userCompany
                break;
            case 3:
                endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/User/self/' + userId
                break;
        }

        fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            // body: JSON.stringify(data),
        })

            .then(resp => resp.json())
            .then(resp => setUserData(resp))

            .catch(error => {
                console.log(error);
            })
    }
    
    useEffect(() => {
        render()
    }
        , [render.length, userCompany, userRole, userId])

    function RefreshTable() {
        var endpoint = ""

        switch (userRole) {
            case 1:
                endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/User/WithCarrier'
                break;
            case 2:
                endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/User/filtered/' + userCompany
                break;
            case 3:
                endpoint = process.env.REACT_APP_HOST_BACKEND + '/api/User/self/' + userId
                break;
        }

        fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
        })
            .then(resp => resp.json())
            .then(resp => {
                setRowData(
                    resp.map((user) =>
                    ({
                        User: user.userId,
                        Role: user.role.name,
                        Carrier: user.carrier.carrierName,
                        carrierId: user.carrierId,
                        "First Name": user.firstName,
                        "Last Name": user.lastName,
                        Email: user.email,
                        Phone: user.phone,
                        Active: user.active,
                        userForm: user.userForm,
                    })
                    )
                )
                gridRef.current.api.refreshCells();
            })
            .catch(error => {
                console.log(error);
            })
    }

    const list = userData.map((user) =>
    ({
        User: user.userId,
        Role: user.role.name,
        Carrier: user.carrier.carrierName,
        carrierId: user.carrierId,
        "First Name": user.firstName,
        "Last Name": user.lastName,
        Email: user.email,
        Phone: user.phone,
        Active: user.active,
        userForm: user.userForm,
    })
    )

    //   useEffect(() => {
    //     setRowData(list)
    //     // restoreState()
    // }, [JSON.stringify(list)])

    useEffect(() => {
        if (list.length > 0 && dataCheck === 0) {
            setRowData(list)
            if (localStorage.getItem("user") != null) {
                restoreState()
            }
            setDataCheck(1)
        }
    })

    function columnDef() {
        var def = []
        if (userRole == 1) {
            def = [
                {
                    field: 'Role', maxWidth: 1000,
                    //cellRenderer: UserRoleRender
                },
                // { field: 'Carrier', cellRenderer: UserGetCompany },
                { field: 'Carrier' },
                {
                    field: 'carrierId',
                    headerName: 'CarrierID',
                    maxWidth: 108
                },
                { field: 'First Name' },
                { field: 'Last Name' },
                { field: 'Email' },
                { field: 'Phone' },
                { field: 'Active', maxWidth: 100, floatingFilter: false, cellRenderer: UserActiveStateRenderer, cellRendererParams: { "refresh": () => RefreshTable(), "role": userRole, "userId": userId }, tooltipField: 'Active' },
                { field: 'Edit', maxWidth: 100, floatingFilter: false, cellRenderer: UserEdit, cellRendererParams: { "refresh": () => RefreshTable(), "userData": userData, "role": userRole, "userId": userId } },
            ]
        } else {
            def = [
                {
                    field: 'Role', maxWidth: 1000,
                    //cellRenderer: UserRoleRender
                },
                { field: 'First Name' },
                { field: 'Last Name' },
                { field: 'Email' },
                { field: 'Phone' },
                { field: 'Active', maxWidth: 100, cellRenderer: UserActiveStateRenderer, cellRendererParams: { "refresh": () => RefreshTable(), "userData": userData, "role": userRole, "userId": userId }, tooltipField: 'Active' },
                { field: 'Edit', maxWidth: 100, floatingFilter: false, cellRenderer: UserEdit, cellRendererParams: { "refresh": () => RefreshTable(),"userData": userData, "role": userRole, "userId": userId } },
            ]
        }
        return def
    }
    const [columnDefs, setColumnDefs] = useState(columnDef())

    

    const defaultColDef = useMemo(() => {

        return {
            flex: 1,
            filter: true,
            floatingFilter: true,
            sortable: true,
            resizable: true,
            menuTabs: ['columnsMenuTab'],
            columnsMenuParams: {
                // suppresses updating the layout of columns as they are rearranged in the grid
                suppressSyncLayoutWithGrid: true,
            },
            tooltipComponent: ToolTip,

        };
    }, []);

    const paginationNumberFormatter = useCallback(function (params) {
        return '[' + params.value.toLocaleString() + ']';
    }, []);

    // const onPageSizeChanged = useCallback(() => {
    //     var value = document.getElementById('page-size').value;
    //     gridRef.current.api.paginationSetPageSize(Number(value));
    // }, []);

    const onPageSizeChanged = useCallback((event) => {
        var value = event.target.value;

        gridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.carrierGridRef != 'undefined') props.carrierGridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.driverGridRef != 'undefined') props.driverGridRef.current.api.paginationSetPageSize(Number(value));
        if(typeof props.auditLogGridRef != 'undefined') props.auditLogGridRef.current.api.paginationSetPageSize(Number(value));
        localStorage.setItem('page', value);
        props.setPage(value)
        const settings = {
            "page": value
        }

        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/UserSetting/update/' + userSettings, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(settings),
        })
            .catch(error => {
                console.log(error);
            })
    }, [props]);

    const saveState = useCallback(() => {
        window.colState = gridRef.current.columnApi.getColumnState();
        var data = JSON.stringify(gridRef.current.columnApi.getColumnState());
        localStorage.setItem('user', data);
        console.log('column state saved');
        const settings = {
            "userCol": data
        }
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/UserSetting/update/' + userSettings, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(settings),
        })
            //.then(resp => {
            //    if (resp.status == 200) {
            //        PostAuditLog("User Edit", "User edit successful.")
            //    } else {
            //        PostAuditLog("User Edit", "User edit failed.")
            //    }
            //})
            .catch(error => {
                console.log(error);
            })
    }, []);


    const restoreState = useCallback(() => {

        gridRef.current.columnApi.applyColumnState({
            //   state: window.colState,
            state: JSON.parse(localStorage['user']),
            applyOrder: true,
        });

    }, []);

    const resetState = useCallback(() => {

        gridRef.current.columnApi.resetColumnState();
        gridRef.current.columnApi.applyColumnState({
            state: [
                { colId: 'Account', sort: 'asc', sortIndex: 0 },
            ],
            defaultState: { sort: null },
        });

    }, []);

    const clearFilters = useCallback(() => {
        gridRef.current.api.setFilterModel(null);
        gridRef.current.api.onFilterChanged();
    }, []);

    const handlePageSize = (e) => {
        gridRef.current.api.paginationSetPageSize(Number(e.target.value));
    }

    return (
        <div className="ag-theme-alpine ag-table-size" style={{ width: '100%', height: '100%' }}>
            {/* <TableBar /> */}

            <div style={{ display: 'flex', gap: '10px' }} className="buttons">
                <div style={{ 'padding': '10px 10px', 'backgroundColor': 'white', width: '100%' }} className="carrier-buttons">

                    <div style={{ display: 'flex', gap: '10px' }} className="buttons all-buttons">
                        {/* <CarrierWizard render={render} refresh={RefreshTable} /> */}
                        <UserAdd refreshTable={""} userCompany={userCompany} userRole={userRole} refresh={() => RefreshTable()} userId={userId} role={userRole} />
                        <span>
                            <Dropdown>
                                <Dropdown.Toggle style={{ borderRadius: 50, padding: '6px 13px 6px 25px' }} id="dropdown-basic" className="carrier-buttons column-btn">
                                    Column Settings
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={saveState} href="#">Save</Dropdown.Item>
                                    <Dropdown.Item onClick={restoreState} href="#">Restore</Dropdown.Item>
                                    <Dropdown.Item onClick={resetState} href="#">Reset</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </span>
                        <button className='btn btn-primary' onClick={clearFilters} style={{ borderRadius: '50px', padding: '6px 15px 6px 15px' }}>Clear Filters</button>
                        {/* <button onClick={RefreshTable}>*/}
                        {/*  Refresh All*/}
                        {/*</button> */}
                    </div>
                </div>
            </div>

            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={props.page}
                paginationNumberFormatter={paginationNumberFormatter}
                domLayout={'autoHeight'}
            // onGridReady={onGridReady}
            >

            </AgGridReact>

            <div className="page-size-div">
                Page Size:
                <select value={props.page} onChange={onPageSizeChanged} id="page-size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
            </div>

            {/* <CarrierAdd /> */}
            {/* <CarrierWizard /> */}

        </div>

    );
}

export default User;
