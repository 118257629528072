import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Modal, Button, Form, Dropdown, Toast, ToastContainer, ToastBody } from 'react-bootstrap'
import  { Redirect, useHistory } from 'react-router-dom'
import Header from './Header';
import Footer from './Footer';
import Notification from './Notification';
import Carrier from './Carrier/Carrier';
import InProgress from './InProgress';
import Login from './Login'
import Driver from './Driver/Driver';
import User from './User/User';
import AuditLog from './AuditLog/AuditLog';
import TableBar from './TableBar';
import Upload from './Upload';
import FileUpload from './FileUpload';
import Download from './Download';
import AccessBody from './UserAccessPermission/AccessBody';
import SFTP from './SFTP'
import CarrierWizard from './Carrier/CarrierWizard'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Row, Col } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { useAuth } from '../context/AuthContext';

function Body() {
    const [showModal, setShowModal] = useState(true);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [key, setKey] = useState();
    const { isAuthenticated, userRole, userSettings, userStatus, userId, logout, user } = useAuth();
    const [aspToken, setaspToken, removeaspToken] = useCookies(".AspNetCore.CookiesC1")
    const [columnSettings, setColumnSettings] = useState([]);
    const [settingsConfigured, setSettingsConfigured] = useState(false);
    const [pagePath, setPagePath] = useState(window.location.pathname == "/register" ? "registration" : "login")
    const [registrationMessage, setRegistrationMessage] = useState("")
    const [showAlert, setShowAlert] = useState(false);
    const toggleShowAlert = () => setShowAlert(!showAlert);
    const [logoutRedirect, setLogoutRedirect] = useState(false);
    const history = useHistory()
    const [page, setPage] = useState(localStorage.getItem("page"));

    const [carrierGridRef, setCarrierGridRef] = useState()
    const [driverGridRef, setDriverGridRef] = useState()
    const [userGridRef, setUserGridRef] = useState()
    const [auditLogGridRef, setAuditLogGridRef] = useState()

    const [carrierRefresh, setCarrierRefresh] = useState()
    const [driverRefresh, setDriverRefresh] = useState()
    const [userRefresh, setUserRefresh] = useState()
    const [auditLogRefresh, setAuditLogRefresh] = useState()
    const [toastMessage, setToastMessage] = useState("")

    let render = <div className="spinner-center">
        <Spinner animation="border" variant="primary" />
    </div>

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const redirectToRegister = async (event) => {
        window.location.replace(process.env.REACT_APP_HOST_AUTH_BACKEND + '/auth/logout');
        await timeout(100);
        window.location.href = '/register';
    }

    const processing = (
        <>Your username is currently being verified. You will be notified by email once this is completed.</>
    )

    const unauthorized = (
        <>Your username is not in our portal. Please <a style={{"cursor": "pointer"}} onClick={() => redirectToRegister()} >register</a> or Contact MTA</>
    )

    const refreshCarrier = (event) => {
        if(typeof carrierRefresh == "function") {
            carrierRefresh()
        }
    }

    const refreshDriver = (event) => {
        if(typeof driverRefresh == "function") {
            driverRefresh()
        }
        
    }

    const refreshUser = (event) => {
        if(typeof userRefresh == "function") {
            userRefresh()
        }
        
    }

    const refreshAuditLog = (event) => {
        if(typeof auditLogRefresh == "function") {
            auditLogRefresh()
        }

    }

    // const refresh = (event) => {
    //     carrierRefresh()
    //     driverRefresh()
    //     userRefresh()
    //     auditLogRefresh()
    // }

    useEffect(() => {
        if (window.location.pathname.includes("/carriers")) {
            setKey("carriers");
        }
        if (window.location.pathname.includes("/drivers")) {
            setKey("drivers");
        }
        if (window.location.pathname.includes("/users")) {
            setKey("users");
        }
        if (window.location.pathname.includes("/audit_log")) {
            setKey("audit_log");
        }
        if (window.location.pathname.includes("sFTP")) {
            setKey("sFTP");
        }
        if (key == undefined) {
            switch (userRole) {
                case "1":
                    setKey("carriers")
                    break;
                case "2":
                    setKey("drivers")
                    break;
                case "3":
                    setKey("users")
                    break;
                default:
                    break;
            }
        }
    }, [userRole])

    useEffect(() => {
        if(key == "carriers"){
            refreshCarrier()
        }
        else if(key == "drivers") {
            refreshDriver()
        }
        else if(key == "users") {
            refreshUser()
        }
        else if(key == "audit_log") {
            refreshAuditLog()
        }
    }, [key])

    function checkAuthorization() {
        var body = <></>
        if (userRole == 1) {
            body =
                <>
                    <Notification />
                    <div className='tabs'>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            {/* <Tab eventKey="carriers" onClick={() => setKey('carriers')} title={"Carrier"}> */}
                            <Tab eventKey="carriers" title={"Carrier"}>
                                <Carrier 
                                    settingsConfigured={settingsConfigured} 
                                    page={page} 
                                    setPage={setPage}
                                    setCarrierGridRef={ref => setCarrierGridRef(ref)}
                                    setCarrierRefresh={refresh => setCarrierRefresh(refresh)}
                                    driverGridRef={driverGridRef}
                                    userGridRef={userGridRef}
                                    auditLogGridRef={auditLogGridRef}
                                />
                            </Tab>
                            <Tab eventKey="drivers" title="Drivers">
                                <Driver 
                                    settingsConfigured={settingsConfigured} 
                                    page={page} 
                                    setPage={setPage}
                                    setDriverGridRef={ref => setDriverGridRef(ref)}
                                    setDriverRefresh={refresh => setDriverRefresh(refresh)}
                                    carrierGridRef={carrierGridRef}
                                    userGridRef={userGridRef}
                                    auditLogGridRef={auditLogGridRef}
                                />
                            </Tab>
                            <Tab eventKey="users" title="Users">
                                <User 
                                    settingsConfigured={settingsConfigured} 
                                    page={page} 
                                    setPage={setPage}
                                    setUserGridRef={ref => setUserGridRef(ref)}
                                    setUserRefresh={refresh => setUserRefresh(refresh)}
                                    carrierGridRef={carrierGridRef}
                                    driverGridRef={driverGridRef}
                                    auditLogGridRef={auditLogGridRef}                                      
                                />
                            </Tab>
                            <Tab eventKey="audit_log" title="Audit Log">
                                <AuditLog 
                                    page={page} 
                                    setPage={setPage}
                                    setAuditLogGridRef={ref => setAuditLogGridRef(ref)}
                                    setAuditLogRefresh={refresh => setAuditLogRefresh(refresh)}
                                    carrierGridRef={carrierGridRef}
                                    driverGridRef={driverGridRef}
                                    userGridRef={userGridRef}        
                                />
                                {/* <AuditLog page={page} setPage={setPage} /> */}
                            </Tab>
                            <Tab eventKey="sFTP" title="sFTP">
                                <SFTP />
                            </Tab>
                            
                        </Tabs>
                        <Footer />
                    </div>
                </>
        }
        else if (userRole == 2 && userStatus == "Activated") {
            body =
                <>
                    <div className='tabs'>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3">
                            <Tab eventKey="drivers" title="Drivers">
                                <Driver 
                                    page={page} 
                                    setPage={setPage}
                                    setDriverGridRef={ref => setDriverGridRef(ref)}
                                    setDriverRefresh={refresh => setDriverRefresh(refresh)}
                                    userGridRef={userGridRef}  
                                />
                            </Tab>
                            <Tab eventKey="users" title="Users">
                                <User 
                                    page={page} 
                                    setPage={setPage}
                                    setUserGridRef={ref => setUserGridRef(ref)}
                                    setUserRefresh={refresh => setUserRefresh(refresh)}
                                    driverGridRef={driverGridRef}
                                />
                            </Tab>
                        </Tabs>
                        <Footer />
                    </div>
                </>
        }
        else if (userRole == 3 && userStatus == "Activated") {
            body =
                <>
                    <div className='tabs'>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3">
                            <Tab eventKey="drivers" title="Drivers">
                                <Driver 
                                    page={page} 
                                    setPage={setPage}
                                    setDriverGridRef={ref => setDriverGridRef(ref)}
                                    setDriverRefresh={refresh => setDriverRefresh(refresh)}
                                    userGridRef={userGridRef}      
                                />
                            </Tab>
                            <Tab eventKey="users" title="Users">
                                <User 
                                    page={page} 
                                    setPage={setPage}
                                    setUserGridRef={ref => setUserGridRef(ref)}
                                    setUserRefresh={refresh => setUserRefresh(refresh)}
                                    driverGridRef={driverGridRef}    
                                />
                            </Tab>
                        </Tabs>
                        <Footer />
                    </div>
                </>
        }
        else if (userRole > 1 && userRole < 4) {
            body =
                <>
                    <AccessBody header="Processing" body={processing} />
                </>
        }
        else if (userRole == null) {
            body =
                <>
                    <AccessBody header="Unauthorized" body={unauthorized} />

                </>
        }
        return body;
    }

    if(typeof isAuthenticated != 'undefined') {
        if (isAuthenticated) {

            render = checkAuthorization();
        }
        else if (!isAuthenticated && aspToken[".AspNetCore.CookiesC1"] == undefined) {
            render = <>
                <CarrierWizard modalStatus={true} setShowModal={setShowModal} pagePath={pagePath} setPagePath={setPagePath} setRegistrationMessage={setRegistrationMessage} setShowAlert={setShowAlert} setToastMessage={setToastMessage} />
                <Login modalStatus={true} setShowModal={setShowModal} pagePath={pagePath} setPagePath={setPagePath} setShowAlert={setShowAlert} showAlert={showAlert} registrationMessage={registrationMessage} />
            </>
        }
    }

    // function refreshAllTables() {
    //     carrierRefresh()
    // }



    return (
        <>
            <Header />
            <ToastContainer className='body-toaster' position="top-center">
                <Toast show={showAlert} onClose={toggleShowAlert}>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto"></strong>
                    </Toast.Header>
                    {/* <Toast.Body>Your registration has been completed. An email will be sent when you have been approved</Toast.Body> */}
                    <Toast.Body>
                        Your registration has been completed. An email will be sent when you have been approved
                        <br></br><br></br>
                        {toastMessage}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {render}
        </>
    );
}


export default Body;
