import React, { useState } from 'react';
import { Button, Modal, Form, DropdownButton } from 'react-bootstrap';
import PostAuditLog from '../../utils/Fetches'


export default (props) => {
    const cellValue = props.data.statusData;
    const carrierId = props.data['edit']['id'];

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [status, setStatus] = useState(cellValue.status);

    const [formButtonEnabled, setFormButtonEnabled] = useState(false);
    const [formWarning, setFormWarning] = useState();
    //   var color = (cellValue) ? '#AAEDB1' : '#ff9d98';
    var color = '#d63637';

    switch (cellValue.status) {
        case 'Activated':
        case true:
            color = '#4aa058';
            break;
        case 'Deactivated':
            color = '#d63637';
            break;
        case 'Pending':
            color = '#d3d3d3';
            break;
        case 'Invited':
            color = '#5c9edd';
            break;
        case 'Unverified':
            color = '#e68e3c';
            break;
    }

    const updateStatus = async (event) => {
        event.preventDefault();
        // const currentDate = (new Date()).toISOString().split('T')[0]
        // setFormWarning()
        // Set Data Fields from Form for Carrier Creation
        setFormButtonEnabled(true)
        var validated = true;
        const data = {
            "status": status,
            "carrierName": cellValue.name,
            "address": cellValue.address,
            "postalCode": cellValue.postalCode,
            "city": cellValue.citys,
            "province": cellValue.province,
            "email": cellValue.email,
            "phone": cellValue.phone,
        }

        // Create Carrier through Rest API call
        await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier/update_status/' + carrierId, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(data),
        })
            .then(resp => {
                if (resp.status == 200) {
                    PostAuditLog("Carrier Status Edit", "Editing the status of carrier, " + cellValue.name + " was a success.", props.userId)
                } else {
                    PostAuditLog("Carrier Status Edit", "Editing the status of carrier, " + cellValue.name + " failed.", props.userId)
                    validated = false
                    setFormButtonEnabled(false)
                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the carrier status. Please try again.</i>)
                }
            })
            .catch(error => {
                PostAuditLog("Carrier Status Edit", "Editing the status of carrier, " + cellValue.name + " failed.", props.userId)
                validated = false
                setFormButtonEnabled(false)
                setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the carrier status. Please try again.</i>)
            })

        if (validated == true) {
            props.refresh()
            setShow(false);
            setFormButtonEnabled(false)
        }

    }

    //const handleChange = selectedOption => {
    //  this.setState({ selectedOption });
    //  console.log(`Option selected:`, selectedOption);
    //  console.log(cellValue);
    //};

    //function onChangeColor(event) {
    //  console.log(event.target.value);
    //}

    return (
        <>
            <i className="fa fa-circle" aria-hidden="true" style={{ color: color }} onClick={props.role == 1 ? handleShow : ""}></i>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Carrier Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <Form.Select 
          aria-label="Default select example" 
          defaultValue={cellValue}
          onChange={this.handleChange}
        >

          <option value="3">Three</option>
        </Form.Select> */}

                    <Form.Control
                        as="select"
                        custom
                        defaultValue={cellValue.status}
                        onChange={e => {
                            setStatus(e.target.value);
                        }}
                    >
                        <option value="Activated">Activated</option>
                        <option value="Deactivated">Deactivated</option>
                        <option value="Pending">Pending</option>
                        <option value="Invited">Invited</option>
                        <option value="Unverified">Unverified</option>


                    </Form.Control>
                    {formWarning}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
                    <Button variant="primary" onClick={updateStatus} disabled={formButtonEnabled}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

        // <Dropdown>
        //   <Dropdown.Toggle className='menu-button' id="dropdown-basic">
        //       Menu
        //   </Dropdown.Toggle>

        //   <Dropdown.Menu>
        //       <Dropdown.Item href="#/action-1">Consent Form Expiring</Dropdown.Item>
        //       <Dropdown.Item href="#/action-2">Status Changed</Dropdown.Item>
        //       <Dropdown.Item href="#/action-3">Logout</Dropdown.Item>
        //   </Dropdown.Menu>
        // </Dropdown>
    );
};