import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, DropdownButton } from 'react-bootstrap';
import PostAuditLog from '../../utils/Fetches'

export default (props) => {
    var cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const driverId = props.data['edit']['driverId'];

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [status, setStatus] = useState(cellValue);
    const [formButtonEnabled, setFormButtonEnabled] = useState(false);
    const [formWarning, setFormWarning] = useState();

    //   var color = (cellValue) ? '#AAEDB1' : '#ff9d98';
    //var color = (cellValue) ? '#AAEDB1' : '#FF6666';
    var color = '#FF6666';

    switch (cellValue) {
        case 'Activated':
        case true:
            color = '#4aa058';
            break;
        case 'Deactivated':
            color = '#d63637';
            break;
        case 'Status Changed':
            color = '#e6ce50';
            break;
        case 'Unverified':
            // color = '#fcba05';
            color = '#f28c07';
            break;
        case 'Expired':
            color = '#633894';
            break;
    }

    useEffect(() => {
        if((props.role == 1 || props.role == 2) && cellValue == "Unverified") {
            setStatus("Activated")            
        }
    }, [cellValue])



    const updateStatus = async (event) => {
        event.preventDefault();
        // const currentDate = (new Date()).toISOString().split('T')[0]

        setFormButtonEnabled(true)
        var validated = true

        const data = {
            //"active": JSON.parse(status)
            "active": status

        }

        await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Driver/update_status/' + driverId, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(data),
        })
            .then(resp => {
                if (resp.status == 200) {
                    PostAuditLog("Driver Status Edit", "Editing the status of driver, " + props.data['firstName'] + " " + props.data['lastName'] + " was a success.", props.userId)
                } else {
                    PostAuditLog("Driver Status Edit", "Editing the status of driver, " + props.data['firstName'] + " " + props.data['lastName'] + " was failed.", props.userId)
                    validated = false
                    setFormButtonEnabled(false)
                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the driver status. Please try again.</i>)
                }
            })
            .catch(error => {
                PostAuditLog("Carrier Status Edit", "Editing the status of driver, " + props.data['firstName'] + " " + props.data['lastName'] + " was failed.", props.userId)
                validated = false
                setFormButtonEnabled(false)
                setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the driver status. Please try again.</i>)
            })

        if(validated == true) {
            props.refresh()
            setShow(false);
            setFormButtonEnabled(false)
        }
    }

    return (
        <>
            <i className="fa fa-circle" aria-hidden="true" style={{ color: color }} onClick={handleShow}></i>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Driver Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Control
                        as="select"
                        custom
                        defaultValue={cellValue}
                        onChange={e => {
                            setStatus(e.target.value);
                        }}
                    >
                        <option value="Activated">Activated</option>
                        <option value="Deactivated">Deactivated</option>
                        {/*<option value="Status Changed">Status Changed</option>*/}
                        <option value="Expired">Expired</option>
                    </Form.Control>
                    {formWarning}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
                    <Button variant="primary" onClick={updateStatus} disabled={formButtonEnabled}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};