// import './Header.css';
import React, { useState, useMemo, useCallback, useRef, useEffect, Component } from 'react';
import { Modal, Button, Form, Row, Col, Toast, ToastContainer } from 'react-bootstrap'
import SelectSearch from 'react-select-search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select';
import PostAuditLog from '../../utils/Fetches'
import DatePicker from 'react-datepicker'



import ViewImage from '../ViewImage';

function DriverAdd(props) {

    const [show, setShow] = useState(false);
    const handleClose = () => handleCloseFunc();
    const handleShow = () => { setShow(true); }
    const [carrier, setCarrier] = useState([]);
    const [update, setUpdate] = useState();
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const [selectedOption, setSelectedOption] = useState(null);
    const [driverId, setDriverId] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [driversLicense, setDriversLicense] = useState("");
    const [licensePic, setLicensePic] = useState();
    const [driverForm, setDriverForm] = useState("")
    const [driverChange, setDriverChange] = useState();
    const [consentExpiry, setConsentExpiry] = useState(null);
    const [active, setActive] = useState();
    const [carrierId, setCarrierId] = useState();
    const [carrierKV, setCarrierKV] = useState();
    const [options, setOptions] = useState([]);
    const [driverExists, setDriverExists] = useState();
    const [driverCheckLogo, setDriverCheckLogo] = useState();
    const [driversLicenseData, setDriversLicenseData] = useState();

    const [isDriverFormSelected, setIsDriverFormSelected] = useState(false);
    const [driverFormFile, setDriverFormFile] = useState();
    const [driverAgreement, setDriverAgreement] = useState();
    const [driverFormType, setDriverFormType] = useState();

    const [isConsentFormSelected, setIsConsentFormSelected] = useState(false);
    const [consentFormFile, setConsentFormFile] = useState();
    const [consentFormType, setConsentFormType] = useState();

    const [formValidated, setFormValidated] = useState(true)
    const [driverFileValidated, setDriverFileValidated] = useState(true)
    const [consentFileValidated, setConsentFileValidated] = useState(true)
    const [firstNameWarning, setFirstNameWarning] = useState()
    const [lastNameWarning, setLastNameWarning] = useState()
    const [driversLicenseWarning, setDriversLicenseWarning] = useState()
    const [consentExpiryWarning, setConsentExpiryWarning] = useState()
    const [licenseUploadWarning, setLicenseUploadWarning] = useState()
    const [driverFormWarning, setDriverFormWarning] = useState()
    const [consentFormWarning, setConsentFormWarning] = useState()

    const [formWarning, setFormWarning] = useState()

    const [showAlert, setShowAlert] = useState(false);
    const toggleShowAlert = () => setShowAlert(!showAlert);


    const [driversLicenseButtonEnabled, setDriversLicenseButtonEnabled] = useState(false)
    const [formButtonEnabled, setFormButtonEnabled] = useState(false)
    const [driversLicenseMessage, setDriversLicenseMessage] = useState()

    function handleCloseFunc() {
        setShow(false)
        clearAllFields()
        clearValidations()
    }

    const addDriver = async (event) => {
        // event.preventDefault();
        // const currentDate = (new Date()).toISOString().split('T')[0]
        //setCarrierId(30);
        setFormButtonEnabled(true)
        setDriverChange(true);
        var driverCheck = true;
        //setConsentExpiry("1753-01-01 00:00:00");
        setActive(true);

        const data = {
            //"driverId": driverId,
            "firstName": firstName,
            "lastName": lastName,
            "driversLicense": driversLicense,
            "consentExpiry": consentExpiry,
            "licensePic": licensePic,
            "driverChange": driverChange,
            "carrierId": props.userCompany,
            "Active": "Deactivated"
        }

        const form_data = new FormData()

        form_data.append("firstName", firstName)
        form_data.append("lastName", lastName)
        form_data.append("driversLicense", driversLicense)
        form_data.append("consentExpiry", consentExpiry.toISOString())
        form_data.append("licensePic", null)
        form_data.append("driverForm", null)
        form_data.append("driverChange", false)
        form_data.append("carrierId", props.userCompany)
        form_data.append("Active", "Unverified")

        form_data.append("license", driverFormFile)
        form_data.append("consent", consentFormFile)

        await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Driver/create-driver', {
            method: 'POST',
            credentials: 'include',
            headers: {
                // 'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: form_data,
        })
            .then(resp => {
                if (resp.status == 200) {
                    PostAuditLog("Driver Create", "Adding driver, " + firstName + " " + lastName + " was a success.", props.userId)
                } else {
                    setFormButtonEnabled(false)
                    setFormWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the Driver. Please try again.</i>)
                    PostAuditLog("Driver Create", "Adding driver, " + firstName + " " + lastName + " failed.", props.userId)
                    driverCheck = false;
                    return;
                }
                setFormButtonEnabled(false)
            })
            .catch(error => {
                setFormButtonEnabled(false)
                    setFormWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the Driver. Please try again.</i>)
                    PostAuditLog("Driver Create", "Adding driver, " + firstName + " " + lastName + " failed.", props.userId)
                    driverCheck = false;
                    return;
            })
        // props.refresh()

        if(driverCheck == true) {
            clearAllFields()
            setShow(false);
            toggleShowAlert()
            props.refresh()
        }
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };

    function carrierSelect() {
        if (carrierKV != undefined) {
            let carriers = carrierKV.map((carrier) => ({
                value: carrier.key,
                label: carrier.value.toString()
            }))
            setOptions(carriers)
        }

    }

    const styleSheet = {
        input: (base, state) => ({
            ...base,
            '[type="text"]': {
                fontFamily: 'Helvetica, sans-serif !important',
                fontSize: 13,
                fontWeight: 900,
                color: 'green'
            }
        })
    };

    const driverFormChangeHandler = (event) => {
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        if(!fileTypes.includes(event.target.files[0].type)) {
            setDriverFileValidated(false)
            setLicenseUploadWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } else {
            setDriverFileValidated(true)
            setLicenseUploadWarning();
        }
        setDriverFormType(event.target.files[0].type)
        setDriverFormFile(event.target.files[0]);
        setLicensePic(event.target.files[0].name)
        setIsDriverFormSelected(true);
    };

    const consentFormChangeHandler = (event) => {
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        if(!fileTypes.includes(event.target.files[0].type)) {
            setConsentFileValidated(false)
            setConsentFormWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } else {
            setConsentFileValidated(true)
            setConsentFormWarning();
        }
        setConsentFormType(event.target.files[0].type)
        setConsentFormFile(event.target.files[0]);
        setIsConsentFormSelected(true);
    };

    const handleConsentFormSubmission = (event) => {
        event.preventDefault();

        const formData = new FormData();

        formData.append('File', driverFormFile);

        fetch(
            process.env.REACT_APP_HOST_BACKEND + '/File/UploadFiles',
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setDriverAgreement(result);
                console.log('Success:', result);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const formRecognizer = async (event) => {
        event.preventDefault()
        
        setLicenseUploadWarning()
        if(typeof driverFormFile == 'undefined') {
            setLicenseUploadWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a file to upload.</i>)
            return;
        }
        setDriversLicenseMessage(<i className="las la-search">Analyzing document...</i>)
        setDriversLicenseButtonEnabled(true)
        setDriverCheckLogo()
        const formData = new FormData();
        formData.append('File', driverFormFile);

        await fetch(process.env.REACT_APP_HOST_BACKEND + '/license-form-recognition', {
            method: 'POST',
            credentials: 'include',
            body: formData,
        })
            .then(resp => resp.json())
            .then(resp => {
                if(resp['documents'].length < 1) {
                    setDriversLicenseMessage(<i className="las la-search" style={{color:"red"}}>Analyzation failed. Please try again.</i>)
                    setDriversLicenseButtonEnabled(false)
                    return;
                }
                if(resp['documents'][0]['fields']['Li No']['content'] == null) {
                    setDriversLicenseMessage(<i className="las la-search" style={{color:"red"}}>Driver's license not found.</i>)
                    setDriversLicenseButtonEnabled(false)
                    return;
                }
                setDriversLicenseData(resp['documents'][0]['fields']);
            })
            .catch(error => {
                setDriversLicenseMessage(<i className="las la-search" style={{color:"red"}}>Analyzation failed. The file has to be an image. Please try again.</i>)
                setDriversLicenseButtonEnabled(false)
                return;
            })
    }

    useEffect(() => {
        if(driversLicenseData != undefined) {
            var driversLicense = driversLicenseData['Li No']['content'] != null ? driversLicenseData['Li No']['content'].replaceAll(/\s/g,'') : ""
            var firstName = driversLicenseData['First Name']['content'] != null ? driversLicenseData['First Name']['content'] : ""
            var lastName = driversLicenseData['Last Name']['content'] != null ? driversLicenseData['Last Name']['content'] : ""
            setDriversLicenseButtonEnabled(false)
            setDriversLicenseMessage()
            setFirstName(firstName)
            setLastName(lastName)
            setDriversLicense(driversLicense)
            licenseCheck(driversLicense)
        }
        
    },[driversLicenseData])

    function licenseCheck(value) {
        setDriversLicenseWarning()
        const licenseFormatCheck = new RegExp(/^([A-Z*]{2})-([A-Z*]{2})-([A-Z*]{2})-([A-Z*]{1}[A-Z0-9]{1}[0-9]{2}[A-Z0-9*]{2})$/)
        if(!licenseFormatCheck.test(value)) {
            setDriversLicenseWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>The driver's license format is wrong. Please enter or upload a valid Manitoba driver's license.</i>)
            setDriverCheckLogo()
            return;
        }
        
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Driver/check_license/' + value, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data),
        })
            .then(resp => resp.json())
            .then(resp => 
                {
                    let driverLogo = ""
                    if (resp.length <= 0) {
                        driverLogo = <i className="las la-check-circle">Driver available to add.</i>
                        setDriverExists(false)  
                    } else {
                        if(props.userCompany == resp['carrierId']) {
                            driverLogo= <i className="las la-exclamation-circle" style={{color:"red"}}>Driver is already in your company.</i>
                        } else {
                            driverLogo= <i className="las la-exclamation-circle" style={{color:"red"}}>Driver is active in another company. Please contact the driver.</i>
                        }
                        
                        setDriverExists(true)
                    }
                    setDriverCheckLogo(driverLogo)
                }
            )
            .catch(error => {
                console.log(error);
            })
    }

    function clearValidations() {
        setFirstNameWarning()
        setLastNameWarning()
        setDriversLicenseWarning()
        setConsentExpiryWarning()
        setDriverFormWarning()
        setConsentFormWarning()
        setFormWarning()
    }

    function clearAllFields() {
        setFirstName("")
        setLastName("")
        setDriversLicense("")
        setLicensePic()
        setConsentExpiry(null)
        setIsDriverFormSelected()
        setDriverFormFile()
        setConsentFormFile()
        setDriverCheckLogo()
    }

    function formValidation() {
        clearValidations()
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        const postalCodeCheck = new RegExp(/^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ][ -]?\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d$/i);
        const passwordCheck = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/);
        const emailCheck = new RegExp(/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
        const licenseFormatCheck = new RegExp(/^([A-Z*]{2})-([A-Z*]{2})-([A-Z*]{2})-([A-Z*]{1}[A-Z0-9]{1}[0-9]{2}[A-Z0-9*]{2})$/)
        var validation = true;

        if(typeof driverFormFile == 'undefined') {
            setFormValidated(false)
            validation = false;
            setDriverFormWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>Please select a file to upload.</i>)
        }

        if(typeof consentFormFile == 'undefined') {
            setFormValidated(false)
            validation = false;
            setConsentFormWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>Please select a file to upload.</i>)
        }
        if (!firstName.trim()) {
            setFormValidated(false)
            validation = false;
            setFirstNameWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>First name is not set.</i>)
        }
        if (!lastName.trim()) {
            setFormValidated(false)
            validation = false;
            setLastNameWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>Last name is not set.</i>)
        }
        if (!driversLicense.trim()) {
            setFormValidated(false)
            validation = false;
            setDriversLicenseWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>Driver's License is not set.</i>)
        }

        

        if (!(consentExpiry instanceof Date)) {
            setFormValidated(false)
            validation = false;
            setConsentExpiryWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>Expiration date is not set.</i>)
        }

        if(consentExpiry instanceof Date) {
            if(consentExpiry.setHours(0,0,0,0) > new Date().setHours(0,0,0,0)) {
                setFormValidated(false)
                validation = false;
                setConsentExpiryWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>Expiration date cannot be set to a future date. Please pick a proper date.</i>)
            }
        }

        if(!fileTypes.includes(driverFormType)) {
            setFormValidated(false)
            validation = false;
            setLicenseUploadWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        }

        if(!fileTypes.includes(consentFormType)) {
            setFormValidated(false)
            validation = false;
            setConsentFormWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } 


        
        if (validation == false || driverFileValidated == false || consentFileValidated == false) {
            // validation = true
            return;
        } else {
            addDriver()
        }
    }

    return (
        <>
            {props.role != undefined && (props.role == "2" || props.role == "3") ?
                <ToastContainer className='body-toaster' position="top-center">
                    <Toast show={showAlert} onClose={toggleShowAlert}>
                        <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                            <strong className="me-auto"></strong>
                        </Toast.Header>
                        <Toast.Body className="text-center">Driver has been created. Please wait for an administrator to activate the driver.</Toast.Body>
                    </Toast>
                </ToastContainer>
            :
                <></>
            }
            {props.role != undefined && props.role == "2" || props.role == "3" ? <button className='btn btn-primary' onClick={handleShow} style={{ borderRadius: '50px', width: 130 }}>+ Add Driver</button> : <button className='btn btn-primary' onClick={handleShow} style={{ borderRadius: '50px', width: 130 }} disabled>+ Add Driver</button>}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Driver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Label>License Image*</Form.Label>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <div>
                                    <input type="file" accept="image/*,.pdf" name="file" onChange={driverFormChangeHandler} />
                                    {isDriverFormSelected ? (
                                        <div>
                                            {driverFormFile != null ? <p>Filename: {driverFormFile.name}</p> : <p>Upload a file to show details</p>}
                                        </div>
                                    ) : (
                                        <p>Upload a file to show details</p>
                                    )}
                                </div>
                                {licenseUploadWarning}
                                {driverFormWarning}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <button className="btn btn-secondary" onClick={formRecognizer} disabled={driversLicenseButtonEnabled}>Upload</button>
                                {/*            </div>*/}
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control type="text" placeholder="Enter first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                {firstNameWarning}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Control placeholder="Enter last name" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                {lastNameWarning}
                            </Form.Group>
                        </Row>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={12}>
                                <Form.Control className="drivers-license" placeholder="Enter driver's license number" type="text" value={driversLicense} onChange={(e) => { setDriversLicense(e.target.value); licenseCheck(e.target.value) }} onBlur={(e) => licenseCheck(e.target.value)} />
                                {driversLicenseWarning} 
                                {/* <Form.Control type="text" value={driversLicense} onChange={(e)=>licenseCheck(e.target.value)} /> */}
                            </Col>
                            {driversLicenseMessage}
                            {driverCheckLogo}
                        </Form.Group>
                        {!driverExists && driverCheckLogo ? 
                            <>
                            <Row className="mb-3">
                                <Col md={7}>
                                    <Form.Group as={Col} >
                                        <div>
                                            <Form.Label>Consent Form*</Form.Label>
                                            <input type="file" accept="image/*,.pdf" name="file" onChange={consentFormChangeHandler} />
                                            {isDriverFormSelected ? (
                                                <div>
                                                    {consentFormFile != null ? <p>Filename: {consentFormFile.name}</p> : <p>Upload a file to show details</p>}
                                                </div>
                                            ) : (
                                                <p>Upload a file to show details</p>
                                            )}
                                        </div>
                                        {consentFormWarning}
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        {/* <button className="btn btn-secondary" onClick={handleConsentFormSubmission}>Upload</button> */}
                                        {/*            </div>*/}
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                <a href="https://trucking.mb.ca/forms/driver.pdf" target="_blank">Download driver form</a>
                                </Col>                                    
                            </Row>

                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={7}>
                                        <Col md={5}>
                                            <Form.Label>
                                                Signed Date:*
                                            </Form.Label>
                                        </Col>
                                        {/*<DatePicker value={new Date(consentExpiry)} onChange={(date: Date) => setConsentExpiry(date)} format={"yyyy-MM-dd"} />*/}
                                        <DatePicker
                                            selected={consentExpiry ? new Date(consentExpiry) : null}
                                            onChange={(date: Date) => setConsentExpiry(date)}
                                            maxDate={new Date()}
                                            dateFormat={'yyyy-MM-dd'}
                                            // peekNextMonth
                                            // showMonthDropdown
                                            // showYearDropdown
                                            // dropdownMode="select"
                                        />
                                        {consentExpiryWarning}
                                    </Col>
                                </Form.Group>
                                {formWarning}
                            </>
                        :
                            <></>
                        
                        }
                        {/* <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                        /> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {!driverExists && driverCheckLogo ?
                        <> 
                            <Button variant="primary" onClick={() => formValidation()} disabled={formButtonEnabled}>
                                Add Driver
                            </Button>
                        </>
                        :
                        <></>
                    }
                    
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverAdd;
