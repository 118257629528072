// import './Header.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import ViewImage from '../ViewImage';
import PostAuditLog from '../../utils/Fetches'


function CarrierEdit(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); };
    // const handleShow = () => { setShow(true); setData(); }
    const handleShow = () => { setShow(true); }
    const [update, setUpdate] = useState();
    const [selectedCarrier, setSelectedCarrier] = useState([])
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

    const [formValidated, setFormValidated] = useState(true)
    const [carrierId, setCarrierId] = useState();
    const [carrierName, setCarrierName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [status, setStatus] = useState("");
    const [carrierAgreement, setCarrierAgreement] = useState('');

    const [carrierNameWarning, setCarrierNameWarning] = useState();
    const [address1Warning, setAddress1Warning] = useState();
    const [addressWarning2, setAddress2Warning] = useState();
    const [cityWarning, setCityWarning] = useState();
    const [provinceWarning, setProvinceWarning] = useState();
    const [postalCodeWarning, setPostalCodeWarning] = useState();
    const [emailWarning, setEmailWarning] = useState();
    const [phoneWarning, setPhoneWarning] = useState();
    const [statusWarning, setStatusWarning] = useState();
    const [formButtonEnabled, setFormButtonEnabled] = useState(false)
    const [formWarning, setFormWarning] = useState(false)

    const [loadingData, setLoadingData] = useState()

    useEffect(() => {
        if(show == true) {
            setLoadingData(<i className="las la-redo-alt"> Loading data...</i>)
            var fetchData = process.env.REACT_APP_HOST_BACKEND + '/api/Carrier/' + props.value['id'];
            fetch(fetchData, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Token ${token['mytoken']}`,
                },
                // body: JSON.stringify(data),

            })
                .then(resp => resp.json())
                .then(resp => setSelectedCarrier(resp))
                // .then(setLoadingData())
                .catch(error => {
                    console.log(error);
            })
        }
    }, [show])

    useEffect(() => {
        setData()
        setLoadingData()
    },[selectedCarrier])
    
    function setData() {
        if (selectedCarrier != undefined) {
            setCarrierId(selectedCarrier['carrierId'])
            setCarrierName(selectedCarrier['carrierName'])
            setAddress1(selectedCarrier['address'])
            setAddress2(selectedCarrier['address2'])
            setCity(selectedCarrier['city'])
            setProvince(selectedCarrier['province'])
            setPostalCode(selectedCarrier['postalCode'])
            setEmail(selectedCarrier['email'])
            setPhone(selectedCarrier['phone'])
            setCarrierAgreement(selectedCarrier['carrierAgreement'])
            setStatus(selectedCarrier['status'])
        }
    }

    function formValidation() {
        clearValidations()
        const postalCodeCheck = new RegExp(/^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ][ -]?\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d$/i);
        const passwordCheck = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/);
        const emailCheck = new RegExp(/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
        var validation = true;
        if (!emailCheck.test(email)) {
            setFormValidated(false)
            validation = false;
            setEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>The format of the email is wrong.</i>)
        }
        if (!carrierName.trim()) {
            setFormValidated(false)
            validation = false;
            setCarrierNameWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Carrier name is not set.</i>)
        }
        if (!address1.trim()) {
            setFormValidated(false)
            validation = false;
            setAddress1Warning(<i class="las la-exclamation-circle" style={{color:"red"}}>Address is not set.</i>)
        }
        if (!city.trim()) {
            setFormValidated(false)
            validation = false;
            setCityWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>City is not set.</i>)
        }
        if (!province.trim()) {
            setFormValidated(false)
            validation = false;
            setProvinceWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Province is not set.</i>)
        }
        if (!postalCodeCheck.test(postalCode)) {
            setFormValidated(false)
            validation = false;
            setPostalCodeWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Please enter a valid postal code.</i>)
        }
        if (!postalCode.trim()) {
            setFormValidated(false)
            validation = false;
            setPostalCodeWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Postal code is not set.</i>)
        }
        if (!email.trim()) {
            setFormValidated(false)
            validation = false;
            setEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Email is not set.</i>)
        }
        if (!phone.trim()) {
            setFormValidated(false)
            validation = false;
            setPhoneWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Phone is not set.</i>)
        }
        // formValidated(true)

        if (formValidated == false || validation == false) {
            // setFormValidated(true);
            validation = true
            return;

        } else {
            updateCarrier()
        }
    }

    const updateCarrier = async (event) => {
        // event.preventDefault();
        // const currentDate = (new Date()).toISOString().split('T')[0]
        var carrierCheck = true
        setFormButtonEnabled(true)

        const data = {
            "carrierId": carrierId,
            "carrierName": carrierName,
            "email": email,
            "phone": phone,
            "status": status,
            "address": address1,
            "address2": address2,
            "city": city,
            "province": province,
            "postalCode": postalCode,
            "carrierAgreement": carrierAgreement
        }

        await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier', {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(data),
        })
            .then(resp => {
                if (resp.status == 200) {
                    PostAuditLog("Carrier Edit", "Editing carrier, " + carrierName + " was a success.", props.userId)
                } else {
                    PostAuditLog("Carrier Edit", "Editing carrier, " + carrierName + " failed.", props.userId)
                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the carrier. Please try again.</i>)
                    carrierCheck=false
                    setFormButtonEnabled(false)
                    return;
                }
            })
            .catch(error => {
                PostAuditLog("Carrier Edit", "Editing carrier, " + carrierName + " failed.", props.userId)
                carrierCheck=false
                setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the carrier. Please try again.</i>)
                setFormButtonEnabled(false)
                return;
            })

        if(carrierCheck == true) {
            props.refresh()
            setFormButtonEnabled(false)
            setShow(false);
        }
        
    }

    let variable = "50cb7b0c-b138-42c0-bb3f-c058de40cfe6.png"

    function clearValidations() {
        setCarrierNameWarning()
        setAddress1Warning()
        setCityWarning()
        setProvinceWarning()
        setPostalCodeWarning()
        setEmailWarning()
        setPhoneWarning()
        // setFirstNameWarning()
        // setLastNameWarning()
        // setUserEmailWarning()
        // setPasswordWarning()
    }
    return (
        <>
            <a onClick={handleShow} style={{ cursor: 'pointer' }}><i className="las la-pencil-alt pencil" aria-hidden="true"></i></a>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit {carrierName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control type="text" value={carrierName} placeholder="Enter carrier name" onChange={(e) => setCarrierName(e.target.value)} />
                                {carrierNameWarning}
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control type="text" value={address1} placeholder="Enter address" onChange={(e) => setAddress1(e.target.value)} />
                                {address1Warning}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Control type="text" value={postalCode} placeholder="Enter postal code" onChange={(e) => setPostalCode(e.target.value)} />
                                {postalCodeWarning}
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control type="text" value={city} placeholder="Enter city" onChange={(e) => setCity(e.target.value)} />
                                {cityWarning}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Control type="text" value={province} placeholder="Enter province" onChange={(e) => setProvince(e.target.value)} />
                                {provinceWarning}
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control type="text" value={email} placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                                {emailWarning}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Control type="text" value={phone} placeholder="Enter phone" onChange={(e) => setPhone(e.target.value)} />
                                {phoneWarning}
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Label>Form*</Form.Label><br></br>
                                {carrierAgreement != '' ? (
                                    <div>
                                        {/*<ViewImage source={"../src/uploads/" + carrierAgreement} />*/}
                                        
                                        {selectedCarrier['carrierAgreement'] != null ? <ViewImage source={selectedCarrier['carrierAgreement']} type={selectedCarrier["carrierAgreementFileType"]} /> : <p>File not uploaded.</p>}

                                    </div>
                                ) : (
                                    <p>No form found.</p>
                                )}
                            </Form.Group>
                        </Row>
                        <hr />
                        {formWarning}
                        {loadingData}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => formValidation()} disabled={formButtonEnabled}>
                        Update Carrier
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CarrierEdit;
