import React, { useState, useEffect, useContext } from "react";
import { useCookies } from 'react-cookie';
export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({
    children
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [auth0Id, setAuth0Id] = useState("");
    const [userRole, setUserRole] = useState("");
    const [userStatus, setUserStatus] = useState("");
    const [userCompany,setUserCompany] = useState("");
    const [userSettings,setUserSettings] = useState("");
    const [userId,setUserId] = useState("");
    const [auth0Token, setAuth0Token, removeAuth0Token] = useCookies()

    const getUser = async () => {
        const response = await fetch(process.env.REACT_APP_HOST_AUTH_BACKEND + '/auth/getUser', {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Access-Control-Allow-Credentials': 'true',
                    },
                });
        const responseStatus = await response.status
        const json = await response.json();
        if(json.isAuthenticated == true) {
        var auth0IdFind = json.claims.find(x => x.type == "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier")
        setIsAuthenticated(json.isAuthenticated);
        setAuth0Id(auth0IdFind.value);
        if (json.isAuthenticated) setUser(json.claims);

            await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/User/auth0id/' + auth0IdFind.value, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Token ${token['mytoken']}`,
                },
                // body: JSON.stringify(data),
            })
            .then(resp => resp.json())
            .then(resp => 
                {
                    if(JSON.stringify(resp) == "[]") {
                        setUserRole(null)
                        return;
                    }
                    setUserId(resp[0]['userId'])
                    setUserCompany(resp[0]['carrierId'])
                    setUserSettings(resp[0]['userSetting']['userSettingId'])
                    setUserRole(resp[0]['roleId'])
                    setUserStatus(resp[0]['active'])

                    if(resp[0]['userSetting'] != null) {
                        if(localStorage.getItem("carrier") == null) {
                            localStorage.setItem('carrier', resp[0]['userSetting']['carrierCol'].toString())
                        }
                        if(localStorage.getItem("driver") == null) {
                            localStorage.setItem('driver', resp[0]['userSetting']['driverCol'].toString())
                        }
                        if(localStorage.getItem("user") == null) {
                            localStorage.setItem('user', resp[0]['userSetting']['userCol'].toString())
                        }
                        if(localStorage.getItem("log") == null) {
                            localStorage.setItem('log', resp[0]['userSetting']['logCol'].toString())
                        }
                        if(localStorage.getItem("page") == null) {
                            localStorage.setItem('page', resp[0]['userSetting']['page'].toString())
                        }
                    }
                }
            )
            .catch(error => {
                console.log(error);
            })
        } else {
            setIsAuthenticated(false)
        }
        
    }
    
    useEffect(() => {
        getUser();
    }, []);

    const login = () => {
        window.location.href = process.env.REACT_APP_HOST_AUTH_BACKEND + '/auth/login';
    }

    const logout = () => {
        window.location.href = process.env.REACT_APP_HOST_AUTH_BACKEND + '/auth/logout';
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                userRole,
                userId,
                userStatus,
                userCompany,
                userSettings,
                isLoading,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};