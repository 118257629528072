import '../styles/Notification.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {Button, Alert} from 'react-bootstrap'
import { useAuth } from '../context/AuthContext';



function Notification() {

    const [show, setShow] = useState(false);
    const [driverData, setDriverData] = useState();
    const [count, setCount] = useState(0);
    const { isAuthenticated, userRole } = useAuth();
    useEffect(() => {
      render()
    },[JSON.stringify[driverData]])


    const render = () => {
      fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Driver/status_changed', {
          method: 'GET',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json',
          },
      })
      .then(resp => resp.json())
      .then(resp => setCount(JSON.stringify(resp)))
      .catch(error => {
          console.log(error);
      })
    }

    useEffect( () => {
        //if (count > 0 && (userRole != undefined && (userRole != "3"))) {
        if (count > 0 && userRole != undefined) {
            setShow(true);
        } else {
            // console.log("its not true!")
        }
    }, [userRole, count]);

    if (show) {
      return (
        <Alert variant="success" show={show} onClose={() => setShow(false)} dismissible>
          <p>
            <a href='drivers?status_changed=true'><b>{count}</b> {count == 1 ? "change in license status from MPI" : "changes in license status from MPI" }</a>
          </p>
        </Alert>
      );
    }
    return <></>;
  }


export default Notification;
