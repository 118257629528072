// import './Header.css';
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import Select from 'react-select';
import PostAuditLog from '../../utils/Fetches'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'


import ViewImage from '../ViewImage';

function DriverEdit(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const handleShow = () => { setShow(true); setData(); }
    const handleShow = () => { setShow(true); }

    const [driverId, setDriverId] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [driversLicense, setDriversLicense] = useState();
    const [originalDriversLicense, setOriginalDriversLicense] = useState("");
    const [licensePic, setLicensePic] = useState();
    const [driverChange, setDriverChange] = useState();
    const [consentExpiry, setConsentExpiry] = useState(new Date());
    const [active, setActive] = useState("");
    const [carrierId, setCarrierId] = useState();
    const [carrierIdValue, setCarrierIdValue] = useState();
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedDriver, setSelectedDriver] = useState([])
    const [selectedCarrier, setSelectedCarrier] = useState([])
    const [carrierKV, setCarrierKV] = useState();
    const [options, setOptions] = useState([]);

    const [formValidated, setFormValidated] = useState(true)
    const [firstNameWarning, setFirstNameWarning] = useState()
    const [lastNameWarning, setLastNameWarning] = useState()
    const [driversLicenseWarning, setDriversLicenseWarning] = useState()
    const [consentExpiryWarning, setConsentExpiryWarning] = useState()

    const [formWarning, setFormWarning] = useState()

    const [formButtonEnabled, setFormButtonEnabled] = useState(false)

    const [isDriverFormSelected, setIsDriverFormSelected] = useState(false);
    const [driverFormFile, setDriverFormFile] = useState();
    const [driverAgreement, setDriverAgreement] = useState();
    const [driverFormType, setDriverFormType] = useState();

    const [isConsentFormSelected, setIsConsentFormSelected] = useState(false);
    const [consentFormFile, setConsentFormFile] = useState();
    const [consentFormType, setConsentFormType] = useState();

    const [driverFileValidated, setDriverFileValidated] = useState(true)
    const [consentFileValidated, setConsentFileValidated] = useState(true)
    const [licenseUploadWarning, setLicenseUploadWarning] = useState()
    const [consentFormWarning, setConsentFormWarning] = useState()
    const [driverFormWarning, setDriverFormWarning] = useState()
    const [driversLicenseButtonEnabled, setDriversLicenseButtonEnabled] = useState(false)

    const [driversLicenseMessage, setDriversLicenseMessage] = useState()
    const [driverCheckLogo, setDriverCheckLogo] = useState();
    const [driversLicenseData, setDriversLicenseData] = useState();
    const [driverExists, setDriverExists] = useState(false);

    const [reuploadView, setReuploadView] = useState(false)
    const [reuploadRender, setReuploadRender] = useState();

    const [loadingData, setLoadingData] = useState()
    


    useEffect(() => {
        if(show == true) {
            setLoadingData(<i className="las la-redo-alt"> Loading data...</i>)
            var fetchDriverData = process.env.REACT_APP_HOST_BACKEND + '/api/Driver/' + props.value['driverId'];
            fetch(fetchDriverData, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Token ${token['mytoken']}`,
                },
                // body: JSON.stringify(data),

            })
                .then(resp => resp.json())
                .then(resp => setSelectedDriver(resp))
                // .then(setLoadingData())
                .catch(error => {
                    console.log(error);
            })
        }
    }, [show])

    useEffect(() => {
        setData()
        setLoadingData()
    }, [selectedDriver])

    function setData() {
        if (selectedDriver != undefined) {
            setDriverId(selectedDriver['driverId'])
            setFirstName(selectedDriver['firstName'])
            setLastName(selectedDriver['lastName'])
            setDriversLicense(selectedDriver['driversLicense'])
            setOriginalDriversLicense(selectedDriver['driversLicense'])
            setLicensePic(selectedDriver['licensePic'])
            setDriverChange(selectedDriver['driversChange'])
            setConsentExpiry(selectedDriver['consentExpiry'])
            setActive(selectedDriver['active'])
            setCarrierId(selectedDriver['carrierId'])
        }

    }

    const updateDriver1 = async (event) => {
        var currentDate = new Date()
        const expiryDate = new Date(consentExpiry)
        if (currentDate > expiryDate) {
            setActive("Expired")
        } else {
            setActive("Activated")
        }
    }

    const updateDriver = async (event) => {
        // event.preventDefault();
        var reuploadDriversLicense = false;
        var reuploadFormFile = false;
        setFormButtonEnabled(true)
        var driverCheck = true
        const data = {
            "driverId": driverId,
            "firstName": firstName,
            "lastName": lastName,
            "driversLicense": driversLicense,
            "consentExpiry": consentExpiry,
            "licensePic": licensePic,
            "driverChange": driverChange,
            "carrierId": carrierId,
            "active": active,
        }

        const form_data = new FormData()

        form_data.append("driverId", driverId)
        form_data.append("firstName", firstName)
        form_data.append("lastName", lastName)
        form_data.append("driversLicense", driversLicense)
        // form_data.append("consentExpiry", consentExpiry.toISOString())
        form_data.append("consentExpiry", consentExpiry)
        form_data.append("licensePic", null)
        form_data.append("driverForm", null)
        form_data.append("driverChange", false)
        form_data.append("carrierId", carrierId)
        form_data.append("Active", active)
        if(driverFormFile != null) {
            form_data.append("license", driverFormFile)
        }

        if(consentFormFile != null) {
            form_data.append("consent", consentFormFile)
        }

        await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Driver/update/' + driverId, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                // 'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: form_data,
        })
            .then(resp => {
                if (resp.status == 200) {
                    PostAuditLog("Driver Edit", "Editing driver, " + firstName + " " + lastName + " was a success.", props.userId)
                    //console.log(driverFormFile != null)
                    //console.log(consentFormFile != null)
                    if(driverFormFile != null) {
                        PostAuditLog("Driver Edit", "Updating the driver's license photo for " + firstName + " " + lastName + ",  was a success.", props.userId)
                    }
                    if(consentFormFile != null) {
                        PostAuditLog("Driver Edit", "Updating the driver's consent form for " + firstName + " " + lastName + ",  was a success.", props.userId)
                    }
                } else {
                    PostAuditLog("Driver Edit", "Editing driver, " + firstName + " " + lastName + " failed.", props.userId)
                    setFormWarning(<i class="las la-exclamation-circle" style={{ color: "red" }}>There was a problem in updating the Driver. Please try again.</i>)
                    driverCheck = false
                    setFormButtonEnabled(false)
                    return;
                }
            })
            .catch(error => {
                PostAuditLog("Driver Edit", "Editing driver, " + firstName + " " + lastName + " failed.", props.userId)
                setFormWarning(<i class="las la-exclamation-circle" style={{ color: "red" }}>There was a problem in updating the Driver. Please try again.</i>)
                driverCheck = false
                setFormButtonEnabled(false)
                return;
            })
        // props.refresh()
        if (driverCheck == true) {
            setFormButtonEnabled(false)
            setShow(false);
            props.refresh()
        }

    }

    useEffect(() => {
        render();
        carrierSelect();
        // getCarrierId();



    }, [JSON.stringify(carrierKV)])

    //function checkActiveStatus() {
    //  var currentDate = new Date()
    //  const expiryDate = new Date(consentExpiry)
    //  if(currentDate > expiryDate) {
    //    setActive("Expired")
    //  } else {
    //    setActive("Activated")
    //  }
    //}

    //useEffect(() => {
    //  checkActiveStatus()
    //},[consentExpiry])

    const render = () => {
        //fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier/kv', {
        //    method: 'GET',
        //    headers: {
        //        'Content-Type': 'application/json',
        //        // 'Authorization': `Token ${token['mytoken']}`,
        //    },
        //    // body: JSON.stringify(data),
        //})

        //    .then(resp => resp.json())

        //    // .then(resp => setCarrierData(resp))
        //    .then(resp => setCarrierKV(resp))

        //    .catch(error => {
        //        console.log(error);
        //    })
    }

    function carrierSelect() {
        if (carrierKV != undefined) {
            let carriers = carrierKV.map((carrier) => ({
                value: carrier.key,
                label: carrier.value.toString()
            }))
            setOptions(carriers)
        }
    }

    function getCarrierId(carrier_id) {
        if (carrierKV != undefined) {
            let currentCarrierId = carrierKV.find(e => console.log("KEY AND CARRIER ID" + e.key + " " + carrier_id))
            // let currentCarrierId = carrierKV.find(e => e.key === carrierId)
            setCarrierIdValue(JSON.stringify(currentCarrierId))
        }
    }

    function clearValidations() {
        setFirstNameWarning()
        setLastNameWarning()
        setDriversLicenseWarning()
        setConsentExpiryWarning()
    }

    function formValidation() {
        clearValidations()
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        const postalCodeCheck = new RegExp(/^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ][ -]?\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d$/i);
        const passwordCheck = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/);
        const emailCheck = new RegExp(/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
        const licenseFormatCheck = new RegExp(/^([A-Z*]{2})-([A-Z*]{2})-([A-Z*]{2})-([A-Z*]{1}[A-Z0-9]{1}[0-9]{2}[A-Z0-9*]{2})$/)
        var validation = true;
        // if (!emailCheck.test(email)) {
        //     setFormValidated(false)
        //     validation = false;
        //     setEmailWarning(<i class="las la-exclamation-circle">The format of the email is wrong.</i>)
        // }

        if(!licenseFormatCheck.test(driversLicense)) { 
            setFormValidated(false)
            validation = false;
            setDriversLicenseWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>The driver's license format is wrong. Please enter or upload a valid Manitoba driver's license.</i>)
        }
        if (!firstName.trim()) {
            setFormValidated(false)
            validation = false;
            setFirstNameWarning(<i class="las la-exclamation-circle" style={{ color: "red" }}>First name is not set.</i>)
        }
        if (!lastName.trim()) {
            setFormValidated(false)
            validation = false;
            setLastNameWarning(<i class="las la-exclamation-circle" style={{ color: "red" }}>Last name is not set.</i>)
        }
        if (!driversLicense.trim()) {
            setFormValidated(false)
            validation = false;
            setDriversLicenseWarning(<i class="las la-exclamation-circle" style={{ color: "red" }}>Driver's License is not set.</i>)
        }
        if (!firstName.trim()) {
            setFormValidated(false)
            validation = false;
            setConsentExpiryWarning(<i class="las la-exclamation-circle" style={{ color: "red" }}>Signed date is not set.</i>)
        }
        if (!consentExpiry instanceof Date) {
            setFormValidated(false)
            validation = false;
            setConsentExpiryWarning(<i className="las la-exclamation-circle" style={{ color: "red" }}>Expiration date is not set.</i>)
        }

        if (new Date(consentExpiry).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)) {
            setFormValidated(false)
            validation = false;
            setConsentExpiryWarning(<i className="las la-exclamation-circle" style={{ color: "red" }}>Expiration date cannot be set to a future date. Please pick a proper date.</i>)
        }

        if(typeof driverFormFile != 'undefined') {
            if(!fileTypes.includes(driverFormType)) {
                setFormValidated(false)
                validation = false;
                setLicenseUploadWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
            }
        }

        if(typeof consentFormFile != 'undefined') {
            if(!fileTypes.includes(consentFormType)) {
                setFormValidated(false)
                validation = false;
                setConsentFormWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
            } 
        }

        if(typeof driverFormFile != 'undefined') {
            if(driverFileValidated == false) return;
        }

        if(typeof consentFormFile != 'undefined') {
            if(consentFileValidated == false) return;
        }

        if (validation == false || driverExists == true) {
            // setFormValidated(true);
            validation = true
            return;
        } else {
            updateDriver()
        }
    }

    const driverFormChangeHandler = (event) => {
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        if(!fileTypes.includes(event.target.files[0].type)) {
            setDriverFileValidated(false)
            setLicenseUploadWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } else {
            setDriverFileValidated(true)
            setLicenseUploadWarning();
        }
        setDriverFormType(event.target.files[0].type)
        setDriverFormFile(event.target.files[0]);
        setLicensePic(event.target.files[0].name)
        setIsDriverFormSelected(true);
    };

    const consentFormChangeHandler = (event) => {
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        if(!fileTypes.includes(event.target.files[0].type)) {
            setConsentFileValidated(false)
            setConsentFormWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } else {
            setConsentFileValidated(true)
            setConsentFormWarning();
        }
        setConsentFormType(event.target.files[0].type)
        setConsentFormFile(event.target.files[0]);
        setIsConsentFormSelected(true);
    };

    const formRecognizer = async (event) => {
        event.preventDefault()
        
        setLicenseUploadWarning()
        if(typeof driverFormFile == 'undefined') {
            setLicenseUploadWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a file to upload.</i>)
            return;
        }
        setDriversLicenseMessage(<i className="las la-search">Analyzing document...</i>)
        setDriversLicenseButtonEnabled(true)
        setDriverCheckLogo()
        const formData = new FormData();
        formData.append('File', driverFormFile);

        await fetch(process.env.REACT_APP_HOST_BACKEND + '/license-form-recognition', {
            method: 'POST',
            credentials: 'include',
            body: formData,
        })
            .then(resp => resp.json())
            .then(resp => {
                if(resp['documents'].length < 1) {
                    setDriversLicenseMessage(<i className="las la-search" style={{color:"red"}}>Analyzation failed. Please try again.</i>)
                    setDriversLicenseButtonEnabled(false)
                    return;
                }
                if(resp['documents'][0]['fields']['Li No']['content'] == null) {
                    setDriversLicenseMessage(<i className="las la-search" style={{color:"red"}}>Driver's license not found.</i>)
                    setDriversLicenseButtonEnabled(false)
                    return;
                }
                setDriversLicenseData(resp['documents'][0]['fields']);
            })
            .catch(error => {
                setDriversLicenseMessage(<i className="las la-search" style={{color:"red"}}>Analyzation failed. The file has to be an image. Please try again.</i>)
                setDriversLicenseButtonEnabled(false)
                return;
            })
    }

    useEffect(() => {
        if(driversLicenseData != undefined) {
            var driversLicense = driversLicenseData['Li No']['content'] != null ? driversLicenseData['Li No']['content'].replaceAll(/\s/g,'') : ""
            var firstName = driversLicenseData['First Name']['content'] != null ? driversLicenseData['First Name']['content'] : ""
            var lastName = driversLicenseData['Last Name']['content'] != null ? driversLicenseData['Last Name']['content'] : ""
            setDriversLicenseButtonEnabled(false)
            setDriversLicenseMessage()
            setFirstName(firstName)
            setLastName(lastName)
            setDriversLicense(driversLicense)
            licenseCheck(driversLicense)
        }
        
    },[driversLicenseData])

    function licenseCheck(value) {
        setDriversLicenseWarning()
        const licenseFormatCheck = new RegExp(/^([A-Z*]{2})-([A-Z*]{2})-([A-Z*]{2})-([A-Z*]{1}[A-Z0-9]{1}[0-9]{2}[A-Z0-9*]{2})$/)
        if(!licenseFormatCheck.test(value)) {
            setDriversLicenseWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>The driver's license format is wrong. Please enter or upload a valid Manitoba driver's license.</i>)
            setDriverCheckLogo()
            return;
        }
        
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Driver/check_license/' + value, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data),
        })
            .then(resp => resp.json())
            .then(resp => 
                {
                    let driverLogo = ""
                    
                    if (resp.length <= 0) {
                        driverLogo = <i className="las la-check-circle">Driver license format is valid and can be updated.</i>
                        setDriverExists(false)  
                    } 
                    else if(resp['driversLicense'] == selectedDriver['driversLicense']) {
                        setDriverExists(false);
                    }
                    else {
                        if(props.userCompany == resp['carrierId']) {
                            driverLogo= <i className="las la-exclamation-circle" style={{color:"red"}}>Driver is already in your company.</i>
                        } else {
                            driverLogo= <i className="las la-exclamation-circle" style={{color:"red"}}>Driver is active in another company. Please contact the driver.</i>
                        }
                        setDriverExists(true)
                    }
                    setDriverCheckLogo(driverLogo)
                }
            )
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <>
            <a onClick={handleShow} style={{ cursor: 'pointer' }}><i className="las la-pencil-alt pencil" aria-hidden="true"></i></a>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Driver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control placeholder="Enter first name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                {firstNameWarning}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Control placeholder="Enter last name" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                {lastNameWarning}
                            </Form.Group>
                        </Row>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm={12}>
                                <Form.Control placeholder="Enter driver's license" type="text" value={driversLicense} onChange={(e) => {setDriversLicense(e.target.value); licenseCheck(e.target.value) }} />
                                {driversLicenseWarning}
                            </Col>
                            {driversLicenseMessage}
                            {driverCheckLogo}
                        </Form.Group>

                        <Row>
                            <Col md={5}>
                                <Form.Label>
                                    License Image:*
                                </Form.Label>
                            </Col>
                            <Col md={2}>
                                <strong>
                                    {selectedDriver['licensePic'] != null ? <ViewImage source={selectedDriver['licensePic']} type={selectedDriver['licensePicFileType']} license={selectedDriver["driversLicense"]} /> : <>File not uploaded</>}
                                </strong>
                            </Col>
                            <Col md={5}>
                                <strong><a onClick={() => setReuploadView(!reuploadView)} style={{cursor:'pointer'}}>Reupload File</a></strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={5}>
                                <Form.Label>
                                    Consent Form:*
                                </Form.Label>
                            </Col>
                            <Col md={2}>
                                <strong>
                                    {selectedDriver['driverForm'] != null ? <ViewImage source={selectedDriver['driverForm']} type={selectedDriver['driverFormFileType']} /> : <>File not uploaded</>}
                                </strong>
                            </Col>
                            <Col md={5}>
                                <strong><a onClick={() => setReuploadView(!reuploadView)} style={{cursor:'pointer'}}>Reupload File</a></strong>
                            </Col>
                        </Row>

                        <Form.Group as={Row} className="mb-3">
                            <Col md={5}>
                                <Form.Label>
                                    Signed Date:*
                                </Form.Label>
                            </Col>
                            <Col sm={12}>
                                <DatePicker
                                    selected={consentExpiry ? new Date(consentExpiry) : null}
                                    onChange={(date: Date) => setConsentExpiry(date.toISOString())}
                                    maxDate={new Date()}
                                    dateFormat={'yyyy-MM-dd'}
                                />
                                {consentExpiryWarning}
                            </Col>
                        </Form.Group>
                        {/* {reuploadRender} */}
                        {reuploadView ? <>
                            <Form.Label><b>Reupload Files</b></Form.Label>
                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <div>
                                    <Form.Label>Drivers License</Form.Label>
                                        <input type="file" accept="image/*,.pdf" name="file" onChange={driverFormChangeHandler} />
                                        {isDriverFormSelected ? (
                                            <div>
                                                {driverFormFile != null ? <p>Filename: {driverFormFile.name}</p> : <p>Upload a file to show details</p>}
                                            </div>
                                        ) : (
                                            <p>Upload a file to show details</p>
                                        )}
                                    </div>
                                    {licenseUploadWarning}
                                    {driverFormWarning}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <button className="btn btn-secondary" onClick={formRecognizer} disabled={driversLicenseButtonEnabled}>Upload</button>
                                    {/*            </div>*/}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Col md={7}>
                                    <Form.Group as={Col} >
                                        <div>
                                            <Form.Label>Consent Form</Form.Label>
                                            <input type="file" accept="image/*,.pdf" name="file" onChange={consentFormChangeHandler} />
                                            {isDriverFormSelected ? (
                                                <div>
                                                    {consentFormFile != null ? <p>Filename: {consentFormFile.name}</p> : <p>Upload a file to show details</p>}
                                                </div>
                                            ) : (
                                                <p>Upload a file to show details</p>
                                            )}
                                        </div>
                                        {consentFormWarning}
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        {/* <button className="btn btn-secondary" onClick={handleConsentFormSubmission}>Upload</button> */}
                                        {/*            </div>*/}
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                <a href="https://trucking.mb.ca/forms/driver.pdf" target="_blank">Download driver form</a>
                                </Col>                                    
                            </Row>
                        </>
                        :
                        <></>
                        }
                        {formWarning}
                        {loadingData}
                    </Form>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={() => formValidation()} disabled={formButtonEnabled}>
                        Update Driver
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverEdit;
