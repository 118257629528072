import React, { useState } from 'react';
import { Button, Modal, Form, DropdownButton } from 'react-bootstrap';
import PostAuditLog from '../../utils/Fetches'

export default (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const userId = props.data['User'];
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [status, setStatus] = useState(cellValue);

    const [formButtonEnabled, setFormButtonEnabled] = useState(false);
    const [formWarning, setFormWarning] = useState();

    //   var color = (cellValue) ? '#AAEDB1' : '#ff9d98';
    var color = '#d63637';

    switch (cellValue) {
        case 'Activated':
        case true:
            color = '#4aa058';
            break;
        case 'Deactivated':
            color = '#d63637';
            break;
        case 'Unverified':
            color = '#e68e3c';
            break;
    }



    const updateStatus = async (event) => {
        event.preventDefault();
        //     // const currentDate = (new Date()).toISOString().split('T')[0]
        
        setFormButtonEnabled(true)
        var validated = true

        // Set Data Fields from Form for Carrier Creation
        const data = {
            "active": status
        }
        // Create User through Rest API call
        await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/User/update_status/' + userId, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            body: JSON.stringify(data),
        })
            .then(resp => {
                if (resp.status == 200) {
                    PostAuditLog("User Status Edit", "Editing the status of user, " + props.data['First Name'] + " " + props.data['Last Name'] + " was a success.", props.userId)
                } else {
                    PostAuditLog("User Status Edit", "Editing the status of user, " + props.data['First Name'] + " " + props.data['Last Name'] + " failed.", props.userId)
                    validated = false
                    setFormButtonEnabled(false)
                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the user status. Please try again.</i>)
                }
            })
            // .then(resp => setUpdate(resp))
            .catch(error => {
                PostAuditLog("User Status Edit", "Editing the status of user, " + props.data['First Name'] + " " + props.data['Last Name'] + " failed.", props.userId)
                    validated = false
                    setFormButtonEnabled(false)
                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the user status. Please try again.</i>)
            })

        if(validated == true) {
            props.refresh()
            setShow(false);
            setFormButtonEnabled(false)
        }
        
    }

    //const handleChange = selectedOption => {
    //  this.setState({ selectedOption });
    //  console.log(`Option selected:`, selectedOption);
    //  console.log(cellValue);
    //};

    //function onChangeColor(event) {
    //  console.log(event.target.value);
    //}

    return (
        <>
            <i className="fa fa-circle" aria-hidden="true" style={{ color: color }} onClick={props.role == 1 ? handleShow : ""}></i>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update user Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        as="select"
                        custom
                        defaultValue={cellValue}
                        onChange={e => {
                            setStatus(e.target.value);
                        }}
                    >
                        <option value="Activated">Activated</option>
                        <option value="Deactivated">Deactivated</option>
                        <option value="Unverified">Unverified</option>
                    </Form.Control>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={updateStatus} disabled={formButtonEnabled}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};