// import './Header.css';
import { ProvidedColumnGroup } from 'ag-grid-community';
import React, { useState, useMemo, useCallback, useRef } from 'react';
import { Modal, Button, Image } from 'react-bootstrap'
// import { Document, Page, pdfjs } from 'react-pdf';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



//import license from '../../src/utils/samplelicense.png';

function ViewImage(props) {
    const [show, setShow] = useState(false);
    const [source, setSource] = useState(props.source);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }
    
      function nextPage() {
        changePage(1);
    }

    // function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    //     setNumPages(nextNumPages);
    //   }
    // const bytesString = String.fromCharCode(...source.props)
    
    return (
        <>
            <a href="#" onClick={handleShow}>
                View
            </a>

            <Modal size="lg" centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.license != null ? "Driver's License: " + props.license : "Preview"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-auto">
                    {props.type == "data:image/jpeg;base64," ? 
                        <img onContextMenu={(e) => e.preventDefault()} className="img-fluid" src={props.type + props.source} alt="" />
                    :
                        <>
                            <div className="document_container">
                                <Document style={{maxWidth: 100}} file={props.type + props.source} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page onContextMenu={(e) => e.preventDefault()} pageNumber={pageNumber} />
                                </Document>
                            </div>
                            <div className="pdf-pagination">
                                <p>
                                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                </p>
                                <div>
                                    <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                        Previous
                                    </Button>
                                    <Button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                    >
                                        Next
                                    </Button>
                                </div>
                                
                            </div>
                        </> 
                        
                    }
                    {/* <img className="img-fluid" src={"data:application/pdf;base64," + props.source} alt="" /> */}
                    
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ViewImage;
