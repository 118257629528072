import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import PostAuditLog from '../utils/Fetches'
import './Upload.css';
import { useAuth } from '../context/AuthContext';


function Upload(props) {

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked

    // const handleClick = event => {
    //     hiddenFileInput.current.click();
    // };

    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const { isAuthenticated, userRole, userId } = useAuth();
    const [carrierData, setCarrierData] = useState()
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };


    const handleClick = () => {
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Driver/upload', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(resp => {
                if (resp.status == 200) {
                    props.setShow(true)
                    props.setVariant("primary")
                    props.setText("File has been uploaded")
                } else {
                    props.setShow(true)
                    props.setVariant("danger")
                    props.setText("File upload failed. Please try again.", userId)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <>

            <div className='buttonTab'>
                <i className="las la-file-upload arrow-style"></i>
                <button
                    className='btn btn-outline-danger btn-lg center'
                    onClick={handleClick} >
                    Upload
                </button>
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }} />
                
            </div>
        </>
    );
}

export default Upload;