// import './Header.css';
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import '../../styles/index.css';
import ReCAPTCHA from "react-google-recaptcha";

function CarrierRegistration(props) {
    const queryParams = new URLSearchParams(window.location.search)
    const carrierNameParam = queryParams.get("carrierName") != null ? queryParams.get("carrierName") : ""
    const addressParam = queryParams.get("address") != null ? queryParams.get("address") : ""
    const postalParam = queryParams.get("postal") != null ? queryParams.get("postal") : ""
    const cityParam = queryParams.get("city") != null ? queryParams.get("city") : ""
    const provinceParam = queryParams.get("province") != null ? queryParams.get("province") : ""
    const emailParam = queryParams.get("email") != null ? queryParams.get("email") : ""
    const phoneParam = queryParams.get("phone") != null ? queryParams.get("phone") : ""
    const carrierIdParam = queryParams.get("carrierId") != null ? queryParams.get("carrierId") : ""

    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); }
    const [carrier, setCarrier] = useState([]);
    const [update, setUpdate] = useState();
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

    const [formValidated, setFormValidated] = useState(true)
    const [carrierAgreementFileValidated, setCarrierAgreementFileValidated] = useState(true)
    const [adminFormFileValidated, setAdminFormFileValidated] = useState(true)
    const [emailValidated, setEmailValidated] = useState(true)
    const [passwordCheckValidated, setPasswordCheckValidated] = useState(true)

    const [carrierId, setCarrierId] = useState(carrierIdParam);
    const [carrierName, setCarrierName] = useState(carrierNameParam);
    const [address1, setAddress1] = useState(addressParam);
    const [address2, setAddress2] = useState();
    const [city, setCity] = useState(cityParam);
    const [province, setProvince] = useState(provinceParam);
    const [postalCode, setPostalCode] = useState(postalParam);
    const [email, setEmail] = useState(emailParam);
    const [phone, setPhone] = useState(phoneParam);
    const [status, setStatus] = useState();

    const [carrierNameWarning, setCarrierNameWarning] = useState();
    const [address1Warning, setAddress1Warning] = useState();
    const [addressWarning2, setAddress2Warning] = useState();
    const [cityWarning, setCityWarning] = useState();
    const [provinceWarning, setProvinceWarning] = useState();
    const [postalCodeWarning, setPostalCodeWarning] = useState();
    const [emailWarning, setEmailWarning] = useState();
    const [phoneWarning, setPhoneWarning] = useState();
    const [statusWarning, setStatusWarning] = useState();
    const [carrierFormFileWarning, setCarrierFormFileWarning] = useState()
    const [adminFormFileWarning, setAdminFormFileWarning] = useState()
    const [recaptcha, setRecaptcha] = useState()
    const [recaptchaWarning, setRecaptchaWarning] = useState()


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState();
    const [firstNameWarning, setFirstNameWarning] = useState();
    const [lastNameWarning, setLastNameWarning] = useState();
    const [emailInAuth0, setEmailInAuth0] = useState(false);
    const [emailInDatabase, setEmailInDatabase] = useState(false);
    const [userEmailWarning, setUserEmailWarning] = useState();
    const [userEmailPrePostWarning, setUserEmailPrePostWarning] = useState();
    const [passwordValidated, setPasswordValidate] = useState(false);
    const [passwordPrePostWarning, setPasswordPrePostWarning] = useState();
    const [passwordWarning, setPasswordWarning] = useState();
    const [formWarning, setFormWarning] = useState();

    //File Upload
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState();

    const [adminFormFile, setAdminFormFile] = useState();
    const [carrierFormFile, setCarrierFormFile] = useState();

    const [adminFormType, setAdminFormType] = useState();
    const [carrierFormType, setCarrierFormType] = useState();

    const [carrierAgreement, setCarrierAgreement] = useState();
    const [adminAgreement, setAdminAgreement] = useState();


    const [isCarrierFormSelected, setIsCarrierFormSelected] = useState(false);
    const [isAdminFormSelected, setIsAdminFormSelected] = useState(false);

    const [formButtonEnabled, setFormButtonEnabled] = useState(false)

    const addCarrier = async (event) => {
        // event.preventDefault();
        var carrierCheck = true;
        var status = ""
        var created_carrier_id = ""
        setFormButtonEnabled(true)

        const carrier_form_data = new FormData()

        carrier_form_data.append("carrierName", carrierName)
        carrier_form_data.append("email", email)
        carrier_form_data.append("phone", phone)
        carrier_form_data.append("status", "Unverified")
        carrier_form_data.append("address", address1)
        carrier_form_data.append("address2", null)
        carrier_form_data.append("city", city)
        carrier_form_data.append("province", province)
        carrier_form_data.append("postalCode", postalCode)
        carrier_form_data.append("carrierAgreement", null)
        carrier_form_data.append("drivers", null)
        carrier_form_data.append('file', carrierFormFile);

        if (queryParams.get("carrierId") != null) {
            // Create Carrier through Rest API call
            await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier/update-registration/' + carrierId, {
                method: 'PATCH',
                headers: {
                    // 'Content-Type': 'application/json',
                    // 'Authorization': `Token ${token['mytoken']}`,
                },
                body: carrier_form_data,
            })
                .then(resp => resp.json())
                .then(resp => 
                    {
                        if(typeof resp != "number") {
                            carrierCheck = false
                            setFormButtonEnabled(false)
                            setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the carrier. Please try again.</i>)
                            window.grecaptcha.reset()
                            return;
                        }
                    }
                )
                .catch(error => {
                    carrierCheck = false
                    setFormButtonEnabled(false)
                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the carrier. Please try again.</i>)
                    window.grecaptcha.reset()
                    return;
                })
        } else {
            // Create Carrier through Rest API call
            await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier/carrier-registration', {
                method: 'POST',
                headers: {
                    // 'Content-Type': 'application/json',
                    // 'Authorization': `Token ${token['mytoken']}`,
                },
                body: carrier_form_data,
            })
                .then(resp => resp.json())
                .then(resp => 
                    {
                        if(typeof resp != "number") {
                            carrierCheck = false
                            setFormButtonEnabled(false)
                            setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the carrier. Please try again.</i>)
                            window.grecaptcha.reset()
                            return;
                        }
                        created_carrier_id = resp
                    }
                )
                .catch(error => {
                    carrierCheck = false
                    setFormButtonEnabled(false)
                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the carrier. Please try again.</i>)
                    window.grecaptcha.reset()
                    return;
                })
        }
        const user_data = {
            "roleId": 2,
            "role": null,
            "carrierId": created_carrier_id == "" ? carrierId : parseInt(created_carrier_id),
            "carrier": null,
            "auth0Id": "",
            "firstName": firstName,
            "lastName": lastName,
            "email": userEmail,
            "phone": phone,
            "active": "string",
            "userForm": null,
            "licensePic": "string",
            "password": password,
            "user": null
        }
        // const currentConsentExpiry = new Date(new Date().setFullYear(new Date().getFullYear() + 2));

        if(carrierCheck == true) {
            await fetch(process.env.REACT_APP_HOST_BACKEND + "/api/User/register", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_AUTH0_ACCESS_TOKEN}`
                },
                body: JSON.stringify(user_data),
            })
                .then(resp => resp.json()
                    .then(
                        async parsedJson => {

                            // console.log("parsedJson['userId'] " + parsedJson['userId'])
                            // console.log("parsedJson[0]['userId'] " + parsedJson[0]['userId'])
                            const user_form_data = new FormData()
                            user_form_data.append("roleId", 2)
                            user_form_data.append("carrierId", created_carrier_id == "" ? carrierId : parseInt(created_carrier_id))
                            user_form_data.append("firstName", firstName)
                            user_form_data.append("lastName", lastName)
                            user_form_data.append("email", userEmail)
                            user_form_data.append("phone", phone)
                            user_form_data.append("active", "Unverified")
                            user_form_data.append("userForm", null)
                            user_form_data.append("licensePic", "string")
                            user_form_data.append("auth0Id", resp.status == 201 ? parsedJson['userId'] : parsedJson[0]['userId'])
                            user_form_data.append('file', adminFormFile);
                            
                            if (resp.status == 201 || resp.status == 409) {
                                if(resp.status == 409) {
                                    await props.setToastMessage("")
                                }
                                if(resp.status == 409) {
                                    await props.setToastMessage("Note: You registered with an account that already exists. Once approved, they can log in using the same username and password as the MTA website")
                                }

                                await fetch(process.env.REACT_APP_HOST_BACKEND + "/api/User/user-registration", {
                                    method: 'POST',
                                    headers: {
                                        // 'Content-Type': 'application/json',
                                        // 'Authorization': `Bearer ${process.env.REACT_APP_AUTH0_ACCESS_TOKEN}`
                                    },
                                    body: user_form_data,
                                })
                                    .then(resp => {
                                        if(resp.status != 200) {
                                            carrierCheck = false
                                            setFormButtonEnabled(false)
                                            setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the user. Please try again.</i>)
                                            window.grecaptcha.reset()
                                            return;
                                        }
                                    }
                                    )
                                    .catch(error => {
                                        carrierCheck = false
                                        setFormButtonEnabled(false)
                                        setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the user. Please try again.</i>)
                                        window.grecaptcha.reset()
                                        return;
                                    })
                            } else {
                                carrierCheck = false
                                setFormButtonEnabled(false)
                                setFormWarning(<i class="las la-exclamation-circle"style={{color:"red"}}>There was a problem in creating the user. Please try again.</i>)
                                window.grecaptcha.reset()
                                return;
                            }
                        }
                    )
                )
                .catch(error => {
                    carrierCheck = false
                    setFormButtonEnabled(false)
                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the user. Please try again.</i>)
                    window.grecaptcha.reset()
                    return;
                })
        }
        if(carrierCheck == true) {
            setFormButtonEnabled(false)
            props.handleClose();
            props.setShowAlert(true);
        }
    }

    // Set File
    const carrierAgreementFormChangeHandler = (event) => {
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        if(!fileTypes.includes(event.target.files[0].type)) {
            setCarrierAgreementFileValidated(false)
            setCarrierFormFileWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } else {
            setCarrierAgreementFileValidated(true)
            setCarrierFormFileWarning();
        }
        setCarrierFormType(event.target.files[0].type)
        setCarrierFormFile(event.target.files[0]);
        setIsCarrierFormSelected(true);
    };

    const adminFormChangeHandler = (event) => {
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        if(!fileTypes.includes(event.target.files[0].type)) {
            setAdminFormFileValidated(false)
            setAdminFormFileWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } else {
            setAdminFormFileValidated(true)
            setAdminFormFileWarning();
        }
        setAdminFormType(event.target.files[0].type)
        setAdminFormFile(event.target.files[0]);
        setIsAdminFormSelected(true);
    };

    // Upload File to database
    const handleCarrierAgreementFormSubmission = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('File', carrierFormFile);

        fetch(
            process.env.REACT_APP_HOST_BACKEND + '/File/UploadFiles',
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setCarrierAgreement(result);
                console.log('Success:', result);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleAdminFormSubmission = (event) => {
        event.preventDefault();

        const formData = new FormData();

        formData.append('File', adminFormFile);

        fetch(
            process.env.REACT_APP_HOST_BACKEND + '/File/UploadFiles',
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setAdminAgreement(result);
                console.log('Success:', result);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    function clearValidations() {
        setCarrierNameWarning()
        setAddress1Warning()
        setCityWarning()
        setProvinceWarning()
        setPostalCodeWarning()
        setEmailWarning()
        setPhoneWarning()
        setFirstNameWarning()
        setLastNameWarning()
        setUserEmailWarning()
        setPasswordWarning()
        setPasswordPrePostWarning()
        setFormWarning()
        setRecaptchaWarning()
    }

    function recapOnChange(value) {
        setRecaptcha(value);
    }

    const recaptchaValidation = async (event) => {
        var valid = false;

        let data = recaptcha
        await fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier/recaptcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(resp => resp.json())
            .then(json =>{
                if(json['success'] == true) {
                    valid = true;
                    return valid
                }
            })
            .catch(error => {
                valid = false;
            })

        return valid;
    }

    async function formValidation() {
        clearValidations()
        window.grecaptcha.reset()
        var recaptchaCheck = await recaptchaValidation()
        var validRecaptcha = true
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        const postalCodeCheck = new RegExp(/^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ][ -]?\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d$/i);
        // const passwordCheck = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
        const passwordCheck = new RegExp(/^(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/);
        const emailCheck = new RegExp(/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
        var validation = true;

        
        if(recaptchaCheck !== true) {
            validRecaptcha = false
            setRecaptchaWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Recaptcha validation failed. Please try again.</i>)
        } 


        if (typeof carrierFormFile == 'undefined') {
            setFormValidated(false)
            validation = false;
            setCarrierFormFileWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>Please select a file to upload.</i>)
        }

        if (typeof adminFormFile == 'undefined') {
            setFormValidated(false)
            validation = false;
            setAdminFormFileWarning(<i className="las la-exclamation-circle" style={{color:"red"}}>Please select a file to upload.</i>)
        }

        if (!emailCheck.test(email)) {
            setFormValidated(false)
            validation = false;
            setEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>The format of the email is wrong.</i>)
        }
        if (!emailCheck.test(userEmail)) {
            setFormValidated(false)
            validation = false;
            setUserEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>The format of the email is wrong.</i>)
        }
        if (!carrierName.trim()) {
            setFormValidated(false)
            validation = false;
            setCarrierNameWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Carrier name is not set.</i>)
        }
        if (!address1.trim()) {
            setFormValidated(false)
            validation = false;
            setAddress1Warning(<i class="las la-exclamation-circle" style={{color:"red"}}>Address is not set.</i>)
        }
        if (!city.trim()) {
            setFormValidated(false)
            validation = false;
            setCityWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>City is not set.</i>)
        }
        if (!province.trim()) {
            setFormValidated(false)
            validation = false;
            setProvinceWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Province is not set.</i>)
        }
        if (!postalCodeCheck.test(postalCode)) {
            setFormValidated(false)
            validation = false;
            setPostalCodeWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Please enter a valid postal code.</i>)
        }
        if (!postalCode.trim()) {
            setFormValidated(false)
            validation = false;
            setPostalCodeWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Postal code is not set.</i>)
        }
        if (!email.trim()) {
            setFormValidated(false)
            validation = false;
            setEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Email is not set.</i>)
        }
        if (!phone.trim()) {
            setFormValidated(false)
            validation = false;
            setPhoneWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Phone is not set.</i>)
        }
        if (!firstName.trim()) {
            setFormValidated(false)
            validation = false;
            setFirstNameWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>First name is not set.</i>)
        }
        if (!lastName.trim()) {
            setFormValidated(false)
            validation = false;
            setLastNameWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Last name is not set.</i>)
        }
        if (!userEmail.trim()) {
            setFormValidated(false)
            setUserEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Email is not set.</i>)
        }
        if (!password.trim()) {
            setFormValidated(false)
            validation = false;
            setPasswordWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Password is not set.</i>)
        }
        if (!passwordCheck.test(password)) {
            setFormValidated(false)
            validation = false;
            setPasswordWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>The password should contain at least 8 characters, one digit, a special character, and one upper case letter.</i>)
        }

        if(!fileTypes.includes(carrierFormType)) {
            setFormValidated(false)
            validation = false;
            setCarrierFormFileWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        }

        if(!fileTypes.includes(adminFormType)) {
            setFormValidated(false)
            validation = false;
            setAdminFormFileWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } 


        if (validation == false || carrierAgreementFileValidated == false || adminFormFileValidated == false || emailValidated == false || passwordCheckValidated == false || validRecaptcha == false) {
            setFormValidated(true);
            // validation = true
            return;
        } else {
            addCarrier()
        }
    }

    function checkEmail(value) {
        let emailWarningMessage = ""
        let user_email_data = {
            email: value
        }
        setUserEmailPrePostWarning();

        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/User/check_email/' + value, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data),
        })
            // .then(resp => resp.json())
            .then(resp => resp.text())
            .then(json => {
                let driverLogo = ""
                if (json == "User found.") {
                    emailWarningMessage = <i class="las la-exclamation-circle" style={{color:"red"}}>The user already exists.</i>
                    setEmailInDatabase(true)
                    setEmailValidated(false)
                }
                else if (json == "User not found.") {
                    emailWarningMessage = <i class="las la-check-circle">User available.</i>
                    setEmailInDatabase(false)
                    setEmailValidated(true)
                }
                setUserEmailPrePostWarning(emailWarningMessage)
                
            })
            .catch(error => {
                console.log(error);
            })
    }

    function checkPassword() {
        let passwordMessage = ""
        if (password != confirmPassword && password != '' && confirmPassword != '') {

            setFormValidated(false)
            setPasswordValidate(false)
            passwordMessage = <i class="las la-exclamation-circle" style={{color:"red"}}>The password confirmation does not match.</i>
            setPasswordCheckValidated(false);
        } else if (password == confirmPassword && password != '' && confirmPassword != '') {
            setPasswordValidate(true)
            setPasswordCheckValidated(true)
        }
        setPasswordPrePostWarning(passwordMessage)
    }

    useEffect(() => {
        checkPassword()
    }, [password, confirmPassword])

    // Don't remove yet for testing
    // https://localhost:44482/register?carrierName=John&address=111+test+st.&postal=R1R1R1&city=Winnipeg&province=Manitoba&email=test@email.com&phone=2042042042

    // const encrypt = btoa("?carrierName=John&address=111+test+st.&postal=R1R1R1&city=Winnipeg&province=Manitoba&email=test@email.com&phone=2042042042")
    // console.log("~query btoa: " + encrypt)
    // console.log("~query atob: " + atob(encrypt))
    // console.log("~query cname: " + carrierNameParam)
    // console.log("~query address: " + addressParam)
    // console.log("~query postal: " + postalParam)
    // console.log("~query city: " + cityParam)
    // console.log("~query province: " + provinceParam)
    // console.log("~query email: " + emailParam)
    // console.log("~query phone: " + phoneParam)  

    return (
        <>
            {/* <a onClick={handleShow} style={{cursor: 'pointer'}}>Add Carrier<i className="las la-plus-alt plus" aria-hidden="true"></i></a> */}
            <Form>
                <Row className="mb-3">
                    <Form.Group as={Col} >

                        <Form.Control type="text" value={carrierName} placeholder="Enter carrier name" onChange={(e) => setCarrierName(e.target.value)} required />
                        {carrierNameWarning}
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} >

                        <Form.Control type="text" value={address1} placeholder="Enter address" onChange={(e) => setAddress1(e.target.value)} required />
                        {address1Warning}
                    </Form.Group>

                    <Form.Group as={Col} >

                        <Form.Control type="text" value={postalCode} placeholder="Enter postal code" onChange={(e) => setPostalCode(e.target.value)} required />
                        {postalCodeWarning}
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} >

                        <Form.Control type="text" value={city} placeholder="Enter city" onChange={(e) => setCity(e.target.value)} required />
                        {cityWarning}
                    </Form.Group>

                    <Form.Group as={Col} >

                        <Form.Control type="text" value={province} placeholder="Enter province" onChange={(e) => setProvince(e.target.value)} required />
                        {provinceWarning}
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} >

                        <Form.Control type="text" value={email} placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} required />
                        {emailWarning}
                    </Form.Group>

                    <Form.Group as={Col} >

                        <Form.Control type="text" value={phone} placeholder="Enter phone" onChange={(e) => setPhone(e.target.value)} required />
                        {phoneWarning}
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>Form*</Form.Label>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Col md={7}>
                        <Form.Group as={Col} >
                            <div>
                                <input type="file" accept="image/*,.pdf" name="file" onChange={carrierAgreementFormChangeHandler} />
                                {isCarrierFormSelected ? (
                                    <div>
                                        <p>Filename: {carrierFormFile.name}</p>
                                    </div>
                                ) : (
                                    <p>Upload a file to show details</p>
                                )}
                            </div>
                            {carrierFormFileWarning}
                        </Form.Group>
                    </Col>
                    <Col md={5}>
                        <a href="https://trucking.mb.ca/forms/carrier.pdf" target="_blank">Download carrier form</a>
                    </Col>
                    <Form.Group as={Col}>
                        {/* <button className="btn btn-secondary" onClick={handleCarrierAgreementFormSubmission}>Upload</button> */}
                    </Form.Group>
                </Row>
                <hr />
                <Modal.Title>Add Admin User</Modal.Title>
                <br />
                <Row className="mb-3">
                    <Form.Group as={Col} >

                        <Form.Control type="text" value={firstName} placeholder="Enter first name" onChange={(e) => setFirstName(e.target.value)} required />
                        {firstNameWarning}
                    </Form.Group>

                    <Form.Group as={Col} >

                        <Form.Control type="text" value={lastName} placeholder="Enter last name" onChange={(e) => setLastName(e.target.value)} required />
                        {lastNameWarning}
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} >

                        <Form.Control type="email" value={userEmail} placeholder="Enter email" onChange={(e) => { setUserEmail(e.target.value); checkEmail(e.target.value) }} required />
                        {userEmailPrePostWarning}
                        {userEmailWarning}
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} >

                        <Form.Control type="password" value={password} placeholder="Enter password" onChange={(e) => { setPassword(e.target.value) }} required />
                        {passwordPrePostWarning}
                        {passwordWarning}
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} >

                        <Form.Control type="password" value={confirmPassword} placeholder="Confirm password" onChange={(e) => { setConfirmPassword(e.target.value) }} required />

                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>Form*</Form.Label>
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Col md={7}>
                        <Form.Group as={Col} >
                            <div>
                                <input type="file" accept="image/*,.pdf" name="file" onChange={adminFormChangeHandler} />
                                {isAdminFormSelected ? (
                                    <div>
                                        <p>Filename: {adminFormFile.name}</p>
                                    </div>
                                ) : (
                                    <p>Upload a file to show details</p>
                                )}
                            </div>
                            {adminFormFileWarning}
                        </Form.Group>
                        <Form.Group as={Col}>
                            {/* <button className="btn btn-secondary" onClick={handleAdminFormSubmission}>Upload</button> */}
                            {/*            </div>*/}
                        </Form.Group>
                    </Col>
                    <Col md={5}>
                        <a href="https://trucking.mb.ca/forms/user.pdf" target="_blank">Download user form</a>
                    </Col>
                    <Form.Group as={Col}>
                        {/* <button className="btn btn-secondary" onClick={handleAdminFormSubmission}>Upload</button> */}
                        {/*            </div>*/}
                    </Form.Group>
                    {formWarning}
                </Row>
                
                <Row>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={recapOnChange}
                    />
                    {recaptchaWarning}
                </Row>
                <br/>
            </Form>

            {/* <Button variant="primary" onClick={addCarrier}> */}
            <Button variant="primary" onClick={() => formValidation()} disabled={formButtonEnabled}>
                Save Carrier
            </Button>
            {/* <Button variant="primary" onClick={props.nextStep}>
        Next Step
      </Button> */}
        </>
    );
}


export default CarrierRegistration;
