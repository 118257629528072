// import './Header.css';
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import Select from 'react-select';
import PostAuditLog from '../../utils/Fetches'
import ViewImage from '../ViewImage';
import { useAuth } from '../../context/AuthContext';

function UserEdit(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const handleShow = () => { setShow(true); setData(); }
    const handleShow = () => { setShow(true);}


    const [carrier, setCarrier] = useState([]);
    const [update, setUpdate] = useState();
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const [selectedOption, setSelectedOption] = useState(null);
    const [carrierKV, setCarrierKV] = useState();

    const [user, setUser] = useState()
    const [roleId, setRoleId] = useState();
    const [userId, setUserId] = useState();
    const [carrierId, setCarrierId] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [auth0Id, setAuth0Id] = useState();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState();
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [userForm, setUserForm] = useState("");
    const [options, setOptions] = useState([]);
    const [carrierIdValue, setCarrierIdValue] = useState();

    const [formValidated, setFormValidated] = useState(true)
    const [firstNameWarning, setFirstNameWarning] = useState();
    const [lastNameWarning, setLastNameWarning] = useState();
    const [emailWarning, setEmailWarning] = useState();
    const [phoneWarning, setPhoneWarning] = useState();
    const [carrierIdWarning, setCarrierIdWarning] = useState();
    const [passwordPrePostWarning, setPasswordPrePostWarning] = useState();
    const [passwordWarning, setPasswordWarning] = useState();
    const [formWarning, setFormWarning] = useState()
    const [formButtonEnabled, setFormButtonEnabled] = useState(false)
    const {userRole } = useAuth();
    const [userRoleCheckbox, setUserRoleCheckbox] = useState(false)

    const [adminFormFile, setAdminFormFile] = useState();
    const [adminFormFileWarning, setAdminFormFileWarning] = useState()
    const [isAdminFormSelected, setIsAdminFormSelected] = useState(false);
    const [adminFormFileValidated, setAdminFormFileValidated] = useState(true)
    const [adminFormType, setAdminFormType] = useState();

    const [loadingData, setLoadingData] = useState()

    useEffect(() => {
        if(show == true) {
            setLoadingData(<i className="las la-redo-alt"> Loading data...</i>)
            var fetchDriverData = process.env.REACT_APP_HOST_BACKEND + "/api/User/" + props.data['User'];
            fetch(fetchDriverData, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Token ${token['mytoken']}`,
                },
                // body: JSON.stringify(data),

            })
                .then(resp => resp.json())
                .then(resp => setUser(resp))
                // .then(setLoadingData())
                .catch(error => {
                    console.log(error);
                })
            }
        
    }, [show])

    useEffect(() => {
        setData()
        setLoadingData()
    }, [user])

    function setData() {
        if (user != undefined) {
            setUserId(user['userId'])
            setFirstName(user['firstName'])
            setLastName(user['lastName'])
            setEmail(user['email'])
            setPhone(user['phone'])
            setCarrierId(user['carrierId'])
            setAuth0Id(user['auth0Id'])
            setUserForm(user['userForm']);
            if(user['roleId'] == 2) {
                setUserRoleCheckbox(true)
            } else if (user['roleId'] == 3) {
                setUserRoleCheckbox(false)
            }
        }
    }

    
    useEffect(() => {
        getCarrierId(carrierId)
    }, [carrierId])

    const handleChange = () => {
        setUserRoleCheckbox(!userRoleCheckbox);
      };

    function getCarrierId(carrier_id) {
        if (carrierKV != undefined) {
            let currentCarrierId = carrierKV.find(e => console.log("key:" + e.key + " carrierid: " + carrierId))
            // let currentCarrierId = carrierKV.find(e => e.key === carrierId)
            setCarrierIdValue(JSON.stringify(currentCarrierId))
        }
    }

    const render = () => {
        // fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier/kv', {
        //     method: 'GET',
        //     credentials: 'include',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         // 'Authorization': `Token ${token['mytoken']}`,
        //     },
        //     // body: JSON.stringify(data),
        // })

        //     .then(resp => resp.json())

        //     // .then(resp => setCarrierData(resp))
        //     .then(resp => setCarrierKV(resp))

        //     .catch(error => {
        //         console.log(error);
        //     })
    }

    useEffect(() => {
        render();
        carrierSelect()
        // getCarrierId(carrierId)
    }, [JSON.stringify(carrierKV)])




    function carrierSelect() {
        if (carrierKV != undefined) {
            let carriers = carrierKV.map((carrier) => ({
                value: carrier.key,
                label: carrier.value.toString()
            }))
            setOptions(carriers)
        }

    }

    const updateUser = async (event) => {
        // event.preventDefault();
        // const currentDate = (new Date()).toISOString().split('T')[0]
        var userCheck = true
        setFormButtonEnabled(true)

        var role = 3
        if(userRoleCheckbox) {
            role = 2
        }
        
        const data = {
            "userId": userId,
            "roleId": userRole == 2 ? role : user['roleId'],
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "phone": phone,
            "auth0Id": auth0Id,
        }

        await fetch(process.env.REACT_APP_HOST_BACKEND + "/api/User/update-auth", {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_AUTH0_ACCESS_TOKEN}`
            },
            body: JSON.stringify(data),
        })
            // .then(resp => {status = resp.status})
            // .then(resp => resp.json()
                .then(resp => {
                        if (resp.status == 201) {
                            fetch(process.env.REACT_APP_HOST_BACKEND + '/api/User/update/' + userId, {
                                method: 'PATCH',
                                credentials: 'include',
                                headers: {
                                    'Content-Type': 'application/json',
                                    // 'Authorization': `Token ${token['mytoken']}`,
                                },
                                body: JSON.stringify(data),
                            })
                            .then(resp => {
                                if (resp.status == 200) {
                                    PostAuditLog("User Edit", "Editing the user, " + props.data['First Name'] + " " + props.data['Last Name'] + " was a success.", props.userId)
                                } else {
                                    PostAuditLog("User Edit", "Editing the status of user, " + props.data['First Name'] + " " + props.data['Last Name'] + " failed.", props.userId)
                                    userCheck = false
                                    setFormButtonEnabled(false)
                                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the Driver. Please try again.</i>)
                                    return;
                                }
                            })
                            .catch(error => {
                                // PostAuditLog("User Edit", "User edit failed.", props.userId)
                                PostAuditLog("User Edit", "Editing the status of user, " + props.data['First Name'] + " " + props.data['Last Name'] + " failed.", props.userId)
                                userCheck = false
                                setFormButtonEnabled(false)
                                setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the Driver. Please try again.</i>)
                                return;
                            })
                        } else{
                            // PostAuditLog("User Edit", "User edit failed.", props.userId)
                            PostAuditLog("User Edit", "Editing the status of user, " + props.data['First Name'] + " " + props.data['Last Name'] + " failed.", props.userId)
                            userCheck = false
                            setFormButtonEnabled(false)
                            setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the Driver. Please try again.</i>)
                            return;
                        }
                    }
                // )
            )
            .catch(error => {
                PostAuditLog("User Edit", "Editing the status of user, " + props.data['First Name'] + " " + props.data['Last Name'] + " failed.", props.userId)
                userCheck = false
                setFormButtonEnabled(false)
                setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in updating the Driver. Please try again.</i>)
                return;
            })
        props.refresh()
        if(userCheck == true) {
            props.refresh()
            setFormButtonEnabled(false)
            setShow(false);
        }

    }

    function clearValidations() {
        setEmailWarning()
        setPhoneWarning()
        setFirstNameWarning()
        setLastNameWarning()
        setCarrierIdWarning()
        setPasswordWarning()
        setPasswordPrePostWarning()
    }

    function formValidation() {
        clearValidations()
        const postalCodeCheck = new RegExp(/^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ][ -]?\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d$/i);
        const passwordCheck = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/);
        const emailCheck = new RegExp(/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
        var validation = true;
        if (!emailCheck.test(email)) {
            setFormValidated(false)
            validation = false;
            setEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>The format of the email is wrong.</i>)
        }
        if (!email.trim()) {
            setFormValidated(false)
            validation = false;
            setEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Email is not set.</i>)
        }
        if (!phone.trim()) {
            setFormValidated(false)
            validation = false;
            setPhoneWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Phone is not set.</i>)
        }
        if (!firstName.trim()) {
            setFormValidated(false)
            validation = false;
            setFirstNameWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>First name is not set.</i>)
        }
        if (!lastName.trim()) {
            setFormValidated(false)
            validation = false;
            setLastNameWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Last name is not set.</i>)
        }

        if (formValidated == false || validation == false) {
            // setFormValidated(true);
            validation = true
            return;
        } else {
            updateUser()
        }
    }

    const adminFormChangeHandler = (event) => {
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        if(!fileTypes.includes(event.target.files[0].type)) {
            setAdminFormFileValidated(false)
            setAdminFormFileWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } else {
            setAdminFormFileValidated(true)
            setAdminFormFileWarning();
        }
        setAdminFormType(event.target.files[0].type)
        setAdminFormFile(event.target.files[0]);
        setIsAdminFormSelected(true);
    };
    
    return (
        <>
            <a onClick={handleShow} style={{ cursor: 'pointer' }}><i className="las la-pencil-alt pencil" aria-hidden="true"></i></a>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                {firstNameWarning}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                {lastNameWarning}
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} disabled/>
                                {emailWarning}
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Control type="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                {phoneWarning}
                            </Form.Group>
                        </Row>

                        {userRole == 2
                        ? 
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Check
                                    type="checkbox"
                                    label="Carrier Admin"
                                    // isValid={userRoleCheckbox}
                                    checked={userRoleCheckbox}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row> 
                        : 
                        <></>
                        }
                        
                        
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Label>Form*</Form.Label><br></br>
                                    {userForm != '' ? (
                                            <div>
                                                {/*<ViewImage source={"../src/uploads/" + carrierAgreement} />*/}
                                                {user['userForm'] != null ? <ViewImage source={user['userForm']} type={user['userFormFileType']} /> : <p>File not uploaded.</p>}
                                            </div>
                                        )
                                        :
                                        (
                                            <p>No form found.</p>
                                        )
                                    }
                                    
                            </Form.Group>
                            <Col md={5}>
                                <a href="https://trucking.mb.ca/forms/user.pdf" target="_blank">Download user form</a>
                            </Col>
                        </Row>
                        {formWarning}
                        {loadingData}
                        {/* {props.role == 1 ? 
                            <Row className="mb-3">
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                    placeholder="Select carrier"
                                />
                                {carrierIdWarning}
                            </Row>
                        :
                        <></>
                        } */}
                        {/* <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control placeholder="Enter password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                {passwordPrePostWarning}
                                {passwordWarning}
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control placeholder="Confirm password" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </Form.Group>
                        </Row> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
                    <Button variant="primary" onClick={() => formValidation()} disabled={formButtonEnabled}>
                        Update User
                    </Button>
                    {/* <button onClick={() => props.refresh()}>
                Refresh All
              </button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UserEdit;
