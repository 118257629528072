// import './Header.css';
import React, { useState, useMemo, useCallback, useRef, useEffect, Component } from 'react';
import { Modal, Button, Form, Row, Col, Toast, ToastContainer } from 'react-bootstrap'
import SelectSearch from 'react-select-search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PostAuditLog from '../../utils/Fetches'
import Select from 'react-select';




import ViewImage from '../ViewImage';

function UserAdd(props) {

    const [show, setShow] = useState(false);
    const handleClose = () => handleCloseFunc();
    const handleShow = () => { setShow(true); }
    const [carrier, setCarrier] = useState([]);
    const [update, setUpdate] = useState();
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const [selectedOption, setSelectedOption] = useState("");

    const [roleId, setRoleId] = useState();
    const [userId, setUserId] = useState();
    const [carrierId, setCarrierId] = useState();
    const [username, setUsername] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState();
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [options, setOptions] = useState([]);
    const [carrierKV, setCarrierKV] = useState();

    const [formValidated, setFormValidated] = useState(true)
    const [emailValidated, setEmailValidated] = useState(true)
    const [firstNameWarning, setFirstNameWarning] = useState();
    const [lastNameWarning, setLastNameWarning] = useState();
    const [emailWarning, setEmailWarning] = useState();
    const [phoneWarning, setPhoneWarning] = useState();
    const [carrierIdWarning, setCarrierIdWarning] = useState();
    const [passwordPrePostWarning, setPasswordPrePostWarning] = useState();
    const [passwordValidated, setPasswordValidate] = useState(false);
    const [passwordWarning, setPasswordWarning] = useState();
    const [formWarning, setFormWarning] = useState();
    const [userEmailPrePostWarning, setUserEmailPrePostWarning] = useState();
    const [passwordCheckValidated, setPasswordCheckValidated] = useState(true)

    const [emailInDatabase, setEmailInDatabase] = useState(false);

    const [formButtonEnabled, setFormButtonEnabled] = useState(false)

    const [showAlert, setShowAlert] = useState(false);
    const toggleShowAlert = () => setShowAlert(!showAlert);
    const [toastMessage, setToastMessage] = useState("");

    const [adminFormFile, setAdminFormFile] = useState();
    const [adminFormFileWarning, setAdminFormFileWarning] = useState()
    const [isAdminFormSelected, setIsAdminFormSelected] = useState(false);
    const [adminFormFileValidated, setAdminFormFileValidated] = useState(true)
    const [adminFormType, setAdminFormType] = useState();

    function handleCloseFunc() {
        setShow(false)
        clearAllFields()
        clearValidations()
    }

    const addUser = async (event) => {
        setFormButtonEnabled(true)
        var userCheck = true;
        const user_data = {
            "roleId": 3,
            "role": null,
            "carrierId": props.userRole == 1 ? selectedOption.value : props.userCompany,
            "carrier": null,
            "auth0Id": "",
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "phone": phone,
            "active": "string",
            "userForm": null,
            "licensePic": "string",
            "password": password,
            "user": null
        }

        await fetch(process.env.REACT_APP_HOST_BACKEND + "/api/User/register", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_AUTH0_ACCESS_TOKEN}`
            },
            body: JSON.stringify(user_data),
        })
            // .then(resp => {status = resp.status})
            .then(resp => resp.json()
                .then(
                    async parsedJson => {
                        const user_db_data = new FormData()
                        
                        user_db_data.append("roleId", 3)
                        user_db_data.append("carrierId", props.userRole == 1 ? selectedOption.value : props.userCompany)
                        user_db_data.append("firstName", firstName)
                        user_db_data.append("lastName", lastName)
                        user_db_data.append("email", email)
                        user_db_data.append("phone", phone)
                        user_db_data.append("active", "Unverified")
                        user_db_data.append("userForm", null)
                        user_db_data.append("licensePic", "string")
                        user_db_data.append("auth0Id", resp.status == 201 ? parsedJson['userId'] : parsedJson[0]['userId'])
                        user_db_data.append('file', adminFormFile);

                        if (resp.status == 201 || resp.status == 409) {
                            if(resp.status == 201) {
                                await setToastMessage("")
                            }

                            if(resp.status == 409) {
                                await setToastMessage("Note: This user is already exists. Once approved, they can log in using the same username and password as the MTA website.")
                            }

                            await fetch(process.env.REACT_APP_HOST_BACKEND + "/api/User/user-creation", {
                              method: 'POST',
                              headers: {
                                //   'Content-Type': 'application/json',
                                //   'Authorization': `Bearer ${process.env.REACT_APP_AUTH0_ACCESS_TOKEN}`
                              },
                              body: user_db_data,
                            })
                              .then(resp => {
                                if(resp.status == 200) {
                                    PostAuditLog("User Add", "Adding user, " + firstName + " " + lastName + " was a success.", props.userId)
                                    props.refresh()
                                }
                                else {
                                    PostAuditLog("User Add", "Adding user, " + firstName + " " + lastName + " failed.", props.userId)
                                    userCheck = false
                                    setFormButtonEnabled(false)
                                    setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the user. Please try again.</i>)
                                    return;
                                }
                            })
                              .catch(error => {
                                PostAuditLog("User Add", "Adding user, " + firstName + " " + lastName + " failed.", props.userId)
                                userCheck = false
                                setFormButtonEnabled(false)
                                setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the user. Please try again.</i>)
                                return;
                            })
                        } else {
                            PostAuditLog("User Add", "Adding user, " + firstName + " " + lastName + " failed.", props.userId)
                            userCheck = false
                            setFormButtonEnabled(false)
                            setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the user. Please try again.</i>)
                            return;
                        }
                    }
                )
            )
            .catch(error => {
                PostAuditLog("User Add", "Adding user, " + firstName + " " + lastName + " failed.", props.userId)
                userCheck = false
                setFormButtonEnabled(false)
                setFormWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>There was a problem in creating the user. Please try again.</i>)
                return;
            })

        props.refresh()
        if(userCheck == true) {
            setFormButtonEnabled(false)
            clearAllFields()
            setShow(false);
            setShowAlert(true);
            // toggleShowAlert()
            props.refresh()
        }
    }

    const render = () => {
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Carrier/kv', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            // body: JSON.stringify(data),
        })

            .then(resp => resp.json())

            // .then(resp => setCarrierData(resp))
            .then(resp => setCarrierKV(resp))

            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        render();
        carrierSelect()

    }, [JSON.stringify(carrierKV)])


    function carrierSelect() {
        if (carrierKV != undefined) {
            let carriers = carrierKV.map((carrier) => ({
                value: carrier.key,
                label: carrier.value.toString()
            }))
            setOptions(carriers)
        }

    }

    function clearValidations() {
        setEmailWarning()
        setPhoneWarning()
        setFirstNameWarning()
        setLastNameWarning()
        setCarrierIdWarning()
        setPasswordWarning()
        setPasswordPrePostWarning()
        setFormWarning()
    }

    function clearAllFields() {
        setFirstName("")
        setLastName("")
        setPassword("")
        setConfirmPassword()
        setEmail("")
        setPhone("")
        setAdminFormFile()
        setAdminFormFileWarning()
        setIsAdminFormSelected(false)
        setAdminFormFileValidated(true)
        setAdminFormType()
        setUserEmailPrePostWarning()
        setPasswordPrePostWarning()
        setAdminFormFileValidated(true)
        setEmailValidated(true)
        setPasswordCheckValidated(true)
    }

    function formValidation() {
        clearValidations()
        const postalCodeCheck = new RegExp(/^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ][ -]?\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\d$/i);
        // const passwordCheck = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
        const passwordCheck = new RegExp(/^(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/);
        const emailCheck = new RegExp(/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']



        var validation = true;
        if (!emailCheck.test(email)) {
            setFormValidated(false)
            validation = false;
            setEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>The format of the email is wrong.</i>)
        }
        if (!email.trim()) {
            setFormValidated(false)
            validation = false;
            setEmailWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Email is not set.</i>)
        }
        if (!phone.trim()) {
            setFormValidated(false)
            validation = false;
            setPhoneWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Phone is not set.</i>)
        }
        if (!firstName.trim()) {
            setFormValidated(false)
            validation = false;
            setFirstNameWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>First name is not set.</i>)
        }
        if (!lastName.trim()) {
            setFormValidated(false)
            validation = false;
            setLastNameWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Last name is not set.</i>)
        }

        if(props.userRole == 1 ) {
            if (selectedOption.value == undefined) {
                setFormValidated(false)
                validation = false;
                setCarrierIdWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Carrier is not set.</i>)
            }
        } else {
            if (!props.userCompany.toString().trim()) {
                setFormValidated(false)
                validation = false;
                setCarrierIdWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Carrier is not set.</i>)
            }
        }

        if (!passwordCheck.test(password)) {
            setFormValidated(false)
            validation = false;
            setPasswordWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>The password should contain at least 8 characters, one digit, a special character, and one upper case letter.</i>)
        }

        if (!password.trim()) {
            setFormValidated(false)
            validation = false;
            setPasswordWarning(<i class="las la-exclamation-circle" style={{color:"red"}}>Password is not set.</i>)
        }

        if(!fileTypes.includes(adminFormType)) {
            setFormValidated(false)
            validation = false;
            setAdminFormFileWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } 
        

        if (validation == false || adminFormFileValidated == false || emailValidated == false || passwordCheckValidated == false) {
            setFormValidated(true);
            validation = true
            return;
        } else {
            addUser()
        }
    }

    const adminFormChangeHandler = (event) => {
        const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif']
        if(!fileTypes.includes(event.target.files[0].type)) {
            setAdminFormFileValidated(false)
            setAdminFormFileWarning(<i className="las la-check-circle" style={{color:"red"}}>Please choose a pdf or image file (png, jpeg, heic, heif).</i>);
        } else {
            setAdminFormFileValidated(true)
            setAdminFormFileWarning();
        }
        setAdminFormType(event.target.files[0].type)
        setAdminFormFile(event.target.files[0]);
        setIsAdminFormSelected(true);
    };

    function checkEmail(value) {
        let emailWarningMessage = ""
        let user_email_data = {
            email: value
        }
        setUserEmailPrePostWarning();

        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/User/check_email/' + value, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data),
        })
            // .then(resp => resp.json())
            .then(resp => resp.text())
            .then(json => {
                let driverLogo = ""
                if (json == "User found.") {
                    emailWarningMessage = <i class="las la-exclamation-circle" style={{color:"red"}}>The user already exists.</i>
                    setEmailInDatabase(true)
                    setEmailValidated(false)
                }
                else if (json == "User not found.") {
                    emailWarningMessage = <i class="las la-check-circle">User available.</i>
                    setEmailInDatabase(false)
                    setEmailValidated(true)
                }
                setUserEmailPrePostWarning(emailWarningMessage)
                
            })
            .catch(error => {
                console.log(error);
            })
    }

    function checkPassword() {
        let passwordMessage = ""
        if (password != confirmPassword && password != '' && confirmPassword != '') {

            setFormValidated(false)
            setPasswordValidate(false)
            passwordMessage = <i class="las la-exclamation-circle" style={{color:"red"}}>The password confirmation does not match.</i>
            setPasswordCheckValidated(false);
        } else if (password == confirmPassword && password != '' && confirmPassword != '') {
            setPasswordValidate(true)
            setPasswordCheckValidated(true)
        }
        setPasswordPrePostWarning(passwordMessage)
    }

    useEffect(() => {
        checkPassword()
    }, [password, confirmPassword])


    return (
        <>
            {props.role != undefined && ((props.role == "1" && toastMessage.length > 0)  || props.role == "2" || props.role == "3") ?
                <ToastContainer className='body-toaster' position="top-center">
                    <Toast show={showAlert} onClose={toggleShowAlert}>
                        <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                            <strong className="me-auto"></strong>
                        </Toast.Header>
                        <Toast.Body className="text-center">
                            {
                                props.role == 1 ?
                                    <>
                                        User has been created.
                                        <br></br><br></br>
                                        {toastMessage}
                                    </>
                                :
                                    <>
                                        User has been created. Please wait for an administrator to activate the user.
                                        <br></br><br></br>
                                        {toastMessage}
                                    </>
                                    

                            }
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            :
                <></>
            }
            <button className='btn btn-primary' onClick={handleShow} style={{ 'borderRadius': '50px', width: 130 }}>+ Add User</button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control placeholder="Enter first name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                {firstNameWarning}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Control placeholder="Enter last name" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                {lastNameWarning}
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control placeholder="Enter email" type="email" value={email} onChange={(e) => {setEmail(e.target.value); checkEmail(e.target.value)}} />
                                {userEmailPrePostWarning}
                                {emailWarning}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Control placeholder="Enter phone number" type="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                {phoneWarning}
                            </Form.Group>
                        </Row>
                        {props.userRole == 1 ? 
                            <Row className="mb-3">
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                    placeholder="Select carrier"
                                />
                                {carrierIdWarning}
                            </Row>
                        :
                        <></>
                        }
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control placeholder="Enter password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                {passwordPrePostWarning}
                                {passwordWarning}
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Control placeholder="Confirm password" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Col md={7}>
                                <Form.Group as={Col} >
                                    <div>
                                        <input type="file" accept="image/*,.pdf" name="file" onChange={adminFormChangeHandler} />
                                        {isAdminFormSelected ? (
                                            <div>
                                                {adminFormFile != null ? <p>Filename: {adminFormFile.name}</p> : <p>Upload a file to show details</p>}
                                            </div>
                                        ) : (
                                            <p>Upload a file to show details</p>
                                        )}
                                    </div>
                                    {adminFormFileWarning}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    {/* <button className="btn btn-secondary" onClick={handleAdminFormSubmission}>Upload</button> */}
                                    {/*            </div>*/}
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <a href="https://trucking.mb.ca/forms/user.pdf" target="_blank">Download user form</a>
                            </Col>
                            <Form.Group as={Col}>
                                {/* <button className="btn btn-secondary" onClick={handleAdminFormSubmission}>Upload</button> */}
                                {/*            </div>*/}
                            </Form.Group>
                        </Row>
                
                        {formWarning}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
                    <Button variant="primary" onClick={() => formValidation()} disabled={formButtonEnabled}>
                        Add User
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UserAdd;