import '../styles/Footer.css';
import logo from '../../src/utils/mta-rpmlogo.png';

function Footer() {
    return (
        
        <div className="footer">
            

            <div className="mta-contact-info">
                <p>
                    <b>Phone:</b> <a href='tel:2046326600'>(204) 632-6600</a> 
                    <b> Email:</b> <a href='mailto:info@trucking.mb.ca'>info@trucking.mb.ca</a>
                </p>
            </div>
            <div className="copyright">
                <p>
                    © {(new Date().getFullYear())} Manitoba Trucking Association. All rights reserved. Developed by <a href="https://nutechdigital.com" target="_blank" rel="noopener noreferrer">Nutech Digital.</a>
                </p>
            </div>

           
         
      
        </div>
       
    );
}

export default Footer;
