import React, { useState, useEffect, useCallback, useRef } from 'react';
import Upload from './Upload';
import Download from './Download';
import { Row, Col, Alert } from 'react-bootstrap'



function SFTP() {
    const [show, setShow] = useState(false);
    const [text, setText] = useState("");
    const [variant, setVariant] = useState("primary");

    return (
            <div className="sftp-main">
            <Alert variant={variant} onClose={() => setShow(false)} show={show} dismissible>
                {text}
            </Alert>

            <div className="sftp-body">
                
                <Upload setShow={setShow} setText={setText} setVariant={setVariant}/>
                <Download setShow={setShow} setText={setText} setVariant={setVariant}/>
                
            </div>
            </div>
            
        
    );
  }


export default SFTP;
