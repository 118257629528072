import React, { useState, useEffect, useCallback, useRef } from 'react';
import '../styles/Header.css';
import logo from '../../src/utils/mta-rpmlogo.png';
import { Button, NavItem, Dropdown, DropdownButton } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';



function Header() {
    const [menuItems, setMenuItems] = useState("")
    const [menuItemsCheck, setMenuItemsCheck] = useState(false)
    const [activeMenuCSS, setActiveMenuCSS] = useState("")
    const { isAuthenticated } = useAuth();
    const [logoutClicked,  setLogoutClicked] = useState(false)

    let list = ""
    useEffect(() => {
        if(menuItemsCheck) {
            list = (<>
                <p>
                    <a href="#" >Menu1</a>
                </p>
                <p>
                    <a href="#" >Menu2</a>
                </p>
                <p>
                    <a href="#" >Menu3</a>
                </p>
            </>)
            setMenuItems(list)
            setActiveMenuCSS(" activated-nav")
        } else {
            list = ""
            setMenuItems(list)
            setActiveMenuCSS("")
        }

    }, [menuItemsCheck])

    // function menuItem

    
    function logOutDelete() {
        localStorage.clear();
        setLogoutClicked(true)
        window.location.href = process.env.REACT_APP_HOST_AUTH_BACKEND + '/auth/logout';
    }

    function menuHandler() {
        setMenuItemsCheck(!menuItemsCheck)
    }

    return (
        <div className="Header">
                <header className="site-header">
                    <div className="site-identity">
                        <img src={logo} alt="MTA and RPM Logo" />
                    </div>
                {isAuthenticated ?
                    <Dropdown >
                        <Dropdown.Toggle className='menu-button' id="dropdown-basic">
                            {logoutClicked == false ? <a href={process.env.REACT_APP_HOST_AUTH_BACKEND + '/auth/logout'} onClick={() => logOutDelete()}>Logout </a> : <a href="#">Logout </a>}
                        </Dropdown.Toggle>
                    </Dropdown>
                    :
                    <Dropdown >
                        <Dropdown.Toggle className='menu-button' id="dropdown-basic">
                            <a href={process.env.REACT_APP_HOST_AUTH_BACKEND + '/auth/login'}>Login </a>
                        </Dropdown.Toggle>
                    </Dropdown>
                }
                </header>
        </div>
    );
}

export default Header;
