// import './Header.css';
import React, { useState, useMemo, useCallback, useRef } from 'react';
import {Modal, Button, Image} from 'react-bootstrap'
import { useAuth } from '../context/AuthContext';
//import license from '../../src/utils/samplelicense.png';

function PostAuditLog(action, status, userId) {
  // const { userId } = useAuth();
  let date = new Date()
  const data = { 
    "userId": userId,
    "action": action,
    "status": status,
    "date": date.toISOString()
  }
  fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Log', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Token ${token['mytoken']}`,
    },
    body: JSON.stringify(data),
  })
  // .then(resp =>)
  .catch(error => {
    console.log(error);
  })
}

export default PostAuditLog;
