import React from 'react';
import PostAuditLog from '../utils/Fetches'
import Button from 'react-bootstrap/Button'
import './Upload.css';
import { useAuth } from '../context/AuthContext';
function Download(props) {
    const { isAuthenticated, userRole, userId } = useAuth();

    const handleParse = () => {
        fetch(process.env.REACT_APP_HOST_BACKEND + '/api/Driver/parse', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token['mytoken']}`,
            },
            // body: JSON.stringify(data),
        })
        .then(resp => {if(resp.status  == 200){
            props.setShow(true)
            props.setVariant("primary")
            props.setText("File has been downloaded")
        } else {
            props.setShow(true)
            props.setVariant("danger")
            props.setText("File download failed. Please try again.")
        }})
        .catch(error => {
            console.log(error);
        })
    }
    return (
        <>
            <div className='buttonTab'>
                <i className="las la-file-download arrow-style"></i>
                <button
                    className='btn btn-outline-danger btn-lg center' onClick={handleParse}>Download
                </button>
            </div>
        </>
    );
}

export default Download;